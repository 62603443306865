import React, { useState, useEffect } from 'react';
import { Spinner, Center, Heading, Flex, Icon, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { FiPaperclip, FiFilter, FiPlus, FiEdit, FiTrash2, FiArrowLeft, FiDownload } from 'react-icons/fi';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Card,
  HStack,
  Box,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react';
import axios from 'axios';
import { baseURL } from '../baseURL.js';
import { jsPDF } from 'jspdf';

const Profile = () => {
  const toast = useToast();
  const [showCertificate, setShowCertificate] = useState(false);
  const [certificateData, setCertificateData] = useState([]);
  const [formData, setFormData] = useState({
    customer: '',
    invoiceNumber: '',
    date: new Date().toISOString().slice(0, 10),
    products: [{ productName: '', quantity: '', price: '', gstperproduct: '' }], // Make sure gstperproduct is included
    taxes: '',
    total: '',
    gst: '',
  });
  const [email, setEmail] = useState();
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    gstNumber: '',
    address: '',
    taluk: '',
    district: '',
    pincode: '',
    state: '',
    bankDetails: {
      bankName: '',
      accountNumber: '',
      ifscCode: '',
      cin: ''
    }
  });
  const [isProfileFound, setIsProfileFound] = useState(true);
  const [loading, setLoading] = useState(true);

  // State for modal
  const [isOpen, setIsOpen] = useState(false);

  const [editCertificateInfo, setEditCertificateInfo] = useState(null);
const [isEditOpen, setIsEditOpen] = useState(false);



  // State for certificate information
  const [certificateInfo, setCertificateInfo] = useState({
    equityShares: '',
    amountPaidPerShare: '',
    registerFolioNo: '',
    certificateNo: '',
    holdersName: '',
    numberOfShares: '',
    distinctiveNos: '',
    date: '',
    authorisedsignatoryOne: '',
    authorisedsignatoryTwo: ''
  });

  useEffect(() => {
    fetchUserProfile();


    fetchCertificateData();
  }, []);


  const fetchCertificateData = async () => {
    try {
      setLoading(true);
      const storedEmail = localStorage.getItem('email');
      setEmail(storedEmail);
      
      const response = await axios.get(`${baseURL}/api/certificateData?email=${storedEmail}`);

      
      // Set certificate data state
      setCertificateData(response.data.certificateData);
    } catch (error) {
      console.error('Error fetching certificate data:', error);
      // Handle error state or display error message
    } finally {
      setLoading(false);
    }
  };
  

  const [formattedDate, setFormattedDate] = useState('');

  const handleCertificateDelete = async (id) => {
    try {
      await axios.delete(`${baseURL}/api/certificateData/${id}`);
      setCertificateData(certificateData.filter(certificate => certificate._id !== id));
    } catch (error) {
      console.error('Error deleting certificate data:', error);
    }

    fetchCertificateData();
  };
  

  const fetchUserProfile = async () => {
    try {
      const storedEmail = localStorage.getItem('email');
      setEmail(storedEmail);

      if (storedEmail) {
        const response = await axios.get(
          `${baseURL}/api/userprofiles?email=${storedEmail}`
        );
        setUserDetails(response.data.userDetails);
        setLoading(false);
      } else {
        console.error('Email not found in localStorage');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === 'User profile not found'
      ) {
        setIsProfileFound(false);
        setLoading(false);
        if (!toast.isActive('profileNotFound')) {
          toast({
            id: 'profileNotFound',
            title: 'Your profile details were incomplete',
            description: 'Please fill in your details.',
            status: 'info',
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      } else {
        toast({
          title: 'Error',
          description:
            'An error occurred while fetching your profile. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    }
  };

  const handleChange = (field, value) => {
    setUserDetails(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleUpdate = async () => {
    try {
      if (
        !userDetails.name ||
        !userDetails.gstNumber ||
        !userDetails.address ||
        !userDetails.taluk ||
        !userDetails.district ||
        !userDetails.pincode ||
        !userDetails.state ||
        !userDetails.bankDetails.accountNumber ||
        !userDetails.bankDetails.bankName ||
        !userDetails.bankDetails.ifscCode ||
        !userDetails.bankDetails.cin
      ) {
        toast({
          title: 'Error',
          description: 'Please fill in all the required fields.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        return;
      }

      const accountNumberRegex = /^[0-9]+$/;
      const ifscCodeRegex = /^[A-Za-z]{4}[0][a-zA-Z0-9]{6}$/;
      const gstNumberRegex = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/;
      const pincodeRegex = /^[0-9]{6}$/;

      if (!accountNumberRegex.test(userDetails.bankDetails.accountNumber)) {
        toast({
          title: 'Error',
          description: 'Please enter a valid account number.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        return;
      }

      if (!ifscCodeRegex.test(userDetails.bankDetails.ifscCode)) {
        toast({
          title: 'Error',
          description: 'Please enter a valid IFSC code.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        return;
      }

      if (!gstNumberRegex.test(userDetails.gstNumber)) {
        toast({
          title: 'Error',
          description: 'Please enter a valid GST number.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        return;
      }

      if (!pincodeRegex.test(userDetails.pincode)) {
        toast({
          title: 'Error',
          description: 'Please enter a valid pincode.',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
        return;
      }

      const response = await axios.put(
        `${baseURL}/api/userprofiles/${userDetails.email}`,
        { userDetails }
      );
      console.log('Profile updated successfully', response.data);
      toast({
        title: 'Profile Updated',
        description: 'Your profile has been successfully updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left'
      });
      setIsProfileFound(true);
    } catch (error) {
      console.error('Error updating profile', error);
      toast({
        title: 'Error',
        description:
          'An error occurred while updating your profile. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };


const handleEdit = (certificate) => {
  
  setEditCertificateInfo(certificate);
  setIsEditOpen(true);
};

const handleEditSubmit = async () => {


  try {
    await axios.put(
      `${baseURL}/api/certificateData/${editCertificateInfo._id}`,
      editCertificateInfo
    );
    // Optionally, update local state or trigger any necessary actions
    setIsEditOpen(false); // Close the edit modal

    toast({
      title: 'Certificate Updated',
      description: 'Your certificate has been successfully updated.',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-left'
    });

  } catch (error) {
    console.error("Error updating certificate data:", error);
    alert("An error occurred while updating the certificate data.");
  }
};

const handleSubmit = async e => {
  try {
    if (
      !userDetails.name ||
      !userDetails.gstNumber ||
      !userDetails.address ||
      !userDetails.taluk ||
      !userDetails.district ||
      !userDetails.pincode ||
      !userDetails.state ||
      !userDetails.bankDetails.accountNumber ||
      !userDetails.bankDetails.bankName ||
      !userDetails.bankDetails.ifscCode ||
      !userDetails.bankDetails.cin
    ) {
      toast({
        title: 'Please fill in all the required fields.',
        description: 'Please fill in your details.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left'
      });
      return;
    }

    const accountNumberRegex = /^[0-9]+$/;
    const ifscCodeRegex = /^[A-Za-z]{4}[0][a-zA-Z0-9]{6}$/;
    const gstNumberRegex = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/;
    const pincodeRegex = /^[0-9]{6}$/;

    if (!accountNumberRegex.test(userDetails.bankDetails.accountNumber)) {
      toast({
        title: 'Please enter a valid account number.',
        description: 'Please fill in your details correctly',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }

    if (!ifscCodeRegex.test(userDetails.bankDetails.ifscCode)) {
      toast({
        title: 'Please enter a valid IFSC code.',
        description: 'Please fill in your details correctly',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }

    if (!gstNumberRegex.test(userDetails.gstNumber)) {
      toast({
        title: 'Please enter a valid GST number',
        description: 'Please fill in your details correctly.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }

    if (!pincodeRegex.test(userDetails.pincode)) {
      toast({
        title: 'Please enter a valid pincode.',
        description: 'Please fill in your details correctly',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return;
    }

    var emailFromLocalStorage = localStorage.getItem('email');
    userDetails.email = emailFromLocalStorage;

    const response = await axios.post(`${baseURL}/api/userprofiles/${emailFromLocalStorage}`, {
      userDetails
    });

    toast({
      title: 'Profile Updated',
      description: 'Your profile has been successfully updated.',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'bottom-left'
    });
    setIsProfileFound(true);
  } catch (error) {
    console.error('Error updating profile', error);
    toast({
      title: 'Error',
      description: 'An error occurred while updating your profile. Please try again later.',
      status: 'error',
      duration: 5000,
      isClosable: true
    });
  }
};



  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCertificateSubmit = () => {
    const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage
    const {
      equityShares,
      amountPaidPerShare,
      registerFolioNo,
      certificateNo,
      holdersName,
      numberOfShares,
      distinctiveNos,

      authorisedsignatoryOne,
      authorisedsignatoryTwo
    } = certificateInfo;
  
    const doc = new jsPDF({
      orientation: 'landscape'
  });
    doc.setFont("helvetica", "bold");

     // Calculate page dimensions
     const pageWidth = doc.internal.pageSize.getWidth();
 
     // Calculate centerX for centering the text
     const centerX = pageWidth / 2;
 
  
    // Add Form No. SH-1 and Share Certificate at top and center
    const formNumber = "Form No. SH-1";
    const shareCertificate = "Share Certificate";
    const formNumberWidth = doc.getStringUnitWidth(formNumber) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const shareCertificateWidth = doc.getStringUnitWidth(shareCertificate) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    
  
    doc.setFontSize(12); // Adjust the font size here
    doc.text(formNumber, centerX - formNumberWidth / 2, 20); // Adjust Y position as needed
    doc.text(shareCertificate, centerX - shareCertificateWidth / 2, 30); // Adjust Y position as needed
  
    // Add additional information
    const additionalInfo1 = "[Pursuant to sub-section (3) of section 46 of the Companies Act, 2013 and rule";
    const additionalInfo2 = "5(2) of the Companies (Share Capital and Debentures) Rules 2014]";
  
    // Calculate the width of each line of text
    const additionalInfo1Width = doc.getStringUnitWidth(additionalInfo1) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const additionalInfo2Width = doc.getStringUnitWidth(additionalInfo2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  
    // Calculate the starting position to center the text horizontally
    const additionalInfoCenterX = (pageWidth - Math.max(additionalInfo1Width, additionalInfo2Width)) / 2;
  
    // Draw the text
    doc.text(additionalInfo1, centerX - additionalInfo1Width / 2, 40); // Adjust Y position as needed
    doc.text(additionalInfo2, centerX - additionalInfo2Width / 2, 45); // Adjust Y position as needed
  
    // Add company information
    const companyName = `${userDetails.name}`;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16); // Adjust the font size here
    const companyNameWidth = doc.getStringUnitWidth(companyName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const centerXCompanyName = (pageWidth - companyNameWidth) / 2; // Calculate the X position to center the text horizontally
    const companyCIN = `CIN : ${userDetails.bankDetails.cin}`;
    const Act = "(Incorporated under the Companies Act, 1956/2013)";
    const companyAddress = `Registered Office: ${userDetails.address}, ${userDetails.taluk}, ${userDetails.district} - ${userDetails.pincode}`;
    doc.text(companyName, centerX - companyNameWidth / 2, 55); // Adjust Y position as needed
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(companyCIN, centerX - (doc.getStringUnitWidth(companyCIN) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2, 65); // Adjust Y position as needed
    doc.text(Act, centerX - (doc.getStringUnitWidth(Act) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2, 70); // Adjust Y position as needed
    doc.text(companyAddress, centerX - (doc.getStringUnitWidth(companyAddress) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2, 75);
    // Adjust Y position as needed
  
    // Add certificate content
    const certificateContent = [
      "This is to certify that the person(s) named in this Certificate is/are the Registered Holder(s) of the within mentioned share(s) bearing", 
      "the distinctive number(s) herein specified in the above named Company subject to the Memorandum and Articles of",
      "Association of the Company and the amount endorsed herein has been paid up on each such share."
    ];
  
    let currentY = 85; // Define currentY variable
    const lineSpacing = 5; // Define lineSpacing variable
  
    certificateContent.forEach(line => {
      doc.text(line,  20, currentY);
      currentY += lineSpacing;
  });

  // Define font size for text within the boxes
  const boxFontSize = 10;

  // Continue adding remaining certificate content
  const remainingContent = [
    "Register Folio No: " + registerFolioNo +  "  Certificate No: " + certificateNo,
    "Name(s) of the Holder(s): " + holdersName,
    "No. of shares held: " + numberOfShares,
    "Distinctive No. (s): From " + distinctiveNos,
  ];

    // Draw rectangle around the lines with thin border lines
    const rect1X = 20;
    const rectY = 100; // Adjust Y position of the rectangle
    const rectWidth = 120; // Adjust width of the rectangle
    const rectHeight = 50; // Adjust height of the rectangle
    doc.setLineWidth(0.3); // Thin border
    doc.rect(rect1X, rectY, rectWidth, rectHeight);
  
    // Add text within the rectangle
    doc.setFontSize(boxFontSize);
    doc.text("EQUITY SHARES EACH OF (Nominal value) RUPEES " + equityShares , rect1X + 5, rectY + 21);
    doc.text("AMOUNT PAID-UP PER SHARE RUPEES " + amountPaidPerShare , rect1X + 5, rectY + 30);
  
    // Draw rectangle around the remaining content with thin border lines
    const rect2X = rect1X + rectWidth + 10; // Adjust X position to create space between rectangles
    // Calculate the height needed for the second rectangle based on the number of lines in the remaining content
    const remainingContentHeight = (remainingContent.length + 1) * 10; // Each line has a height of 10
    const rect2Height = Math.max(rectHeight, remainingContentHeight); // Set the height as the maximum between the default height and the content height

    doc.setLineWidth(0.3); // Thin border
    doc.rect(rect2X, rectY, rectWidth, rect2Height); // Use the calculated height for the second rectangle

    
  
    remainingContent.forEach((line, index) => {
      doc.text(line, rect2X + 5, rectY + 10 + (index * 10)); // Adjust X and Y position as needed
  });
  
    // Draw borders
    doc.setLineWidth(0.3); // Thin border
    doc.rect(5, 5, pageWidth - 10, doc.internal.pageSize.getHeight() - 10); // Outer border
    doc.setLineWidth(2); // Thick border
    doc.rect(7, 7, pageWidth - 14, doc.internal.pageSize.getHeight() - 14); // Inner border
  
    // Add remaining lines outside the border
    const remainingLines = [
      "Given under the common seal of the Company this " + formattedDate,
      "",
      "",
      "",
      "                                         ____________                                                                                                                                   ____________",
      "                                             " +authorisedsignatoryOne+"                                                                                                                                       " +authorisedsignatoryTwo+ " ",
      "",
      "Note: No transfer of the Share(s) comprised in the Certificate can be registered unless accompanied by this Certificate."
    ];
  
    remainingLines.forEach((line, index) => {
      doc.text(line, 20, rectY + rectHeight + 10 + (index * lineSpacing)); // Adjust X and Y position as needed
  });

  
    

  // Add next page with border
  doc.addPage();
  doc.setLineWidth(0.3); // Thin border
  doc.rect(5, 5, pageWidth - 10, doc.internal.pageSize.getHeight() - 10); // Outer border
  doc.setLineWidth(2); // Thick border
  doc.rect(7, 7, pageWidth - 14, doc.internal.pageSize.getHeight() - 14); // Inner border
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");

  // Calculate centerX for centering the text
  const nextPageText = "MEMORANDUM OF TRANSFER OF SHARES MENTIONED OVERLEAF";
  const nextPageTextWidth = doc.getStringUnitWidth(nextPageText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const centerXNextPageText = (pageWidth - nextPageTextWidth) / 2;

  doc.text(nextPageText, centerXNextPageText, 20);
  doc.setFontSize(11);
  // Add table
  const tableRows = [
      ["Date", "Transfer No.", "Registered Folio", "Names(S) of Transferee(S)", "Authorised Signatory"],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""]
  ];

  const startY = 30; // Adjust Y position to leave space after the text
  const rowHeight = 10; // Adjust the height of each row
  const colWidth = (pageWidth - 40) / 5; // Adjust column width based on the number of columns
  const borderWidth = 0.3; // Border width for the table cells

  tableRows.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
          const x = 20 + (colWidth * cellIndex);
          const y = startY + (rowHeight * rowIndex);
          // Draw border rectangle
          doc.setLineWidth(borderWidth);
          doc.rect(x, y, colWidth, rowHeight);
          // Add text inside the rectangle
          doc.text(cell, x + 2, y + (rowHeight / 2), { align: "left", valign: "middle" });
      });
  });

 

      // Save and download the PDF
      doc.save(`Certificate_${holdersName}.pdf`);





  const cin =userDetails.bankDetails.cin;


  const data = {
    certificateInfo: {
      equityShares,
      amountPaidPerShare,
      registerFolioNo,
      certificateNo,
      holdersName,
      numberOfShares,
      distinctiveNos,
      cin,
      authorisedsignatoryOne,
      authorisedsignatoryTwo,
      formattedDate
    },
    userDetails: {
      email: storedEmail,
      name: userDetails.name,
      address: userDetails.address,
      taluk: userDetails.taluk,
      district: userDetails.district,
      pincode: userDetails.pincode
    }
  };



  axios.post(`${baseURL}/api/certificateData`, data)
  .then(response => {
    console.log('Certificate data submitted successfully:', response.data);
    // Handle success
    fetchCertificateData();
  })
  .catch(error => {
    console.error('Error submitting certificate data:', error);
    // Handle error
  });

  fetchCertificateData();

  setIsOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return '';

    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
    
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const formatted = `${day}${daySuffix(day)} day of ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    return formatted;
  };

  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setCertificateInfo(prevState => ({
      ...prevState,
      date: dateValue
    }));
    setFormattedDate(formatDate(dateValue));
  };
  
  

  const handleCertificate = () => {
  
    setShowCertificate(true); // Show certificate section when the button is clicked
  }

  const handleDownloadCertificate = async (id) => {

    try {
      const response = await axios.get(`${baseURL}/api/certificateData/${id}`);
      const certificateData = response.data;
    
  
      const {
        equityShares,
        amountPaidPerShare,
        registerFolioNo,
        certificateNo,
        holdersName,
        numberOfShares,
        distinctiveNos,
        cin,
        authorisedsignatoryOne,
        authorisedsignatoryTwo,
        formattedDate,
        email,
        name,
        address,
        taluk,
        district,
        pincode
      } = certificateData;
  
      const doc = new jsPDF({
        orientation: 'landscape'
      });
      doc.setFont("helvetica", "bold");
  
      // Calculate page dimensions
      const pageWidth = doc.internal.pageSize.getWidth();
  
      // Calculate centerX for centering the text
      const centerX = pageWidth / 2;
  
      // Add Form No. SH-1 and Share Certificate at top and center
      const formNumber = "Form No. SH-1";
      const shareCertificate = "Share Certificate";
      const formNumberWidth = doc.getStringUnitWidth(formNumber) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const shareCertificateWidth = doc.getStringUnitWidth(shareCertificate) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  
      doc.setFontSize(12); // Adjust the font size here
      doc.text(formNumber, centerX - formNumberWidth / 2, 20); // Adjust Y position as needed
      doc.text(shareCertificate, centerX - shareCertificateWidth / 2, 30); // Adjust Y position as needed
  
      // Add additional information
      const additionalInfo1 = "[Pursuant to sub-section (3) of section 46 of the Companies Act, 2013 and rule";
      const additionalInfo2 = "5(2) of the Companies (Share Capital and Debentures) Rules 2014]";
  
      // Calculate the width of each line of text
      const additionalInfo1Width = doc.getStringUnitWidth(additionalInfo1) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const additionalInfo2Width = doc.getStringUnitWidth(additionalInfo2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  
      // Draw the text
      doc.text(additionalInfo1, centerX - additionalInfo1Width / 2, 40); // Adjust Y position as needed
      doc.text(additionalInfo2, centerX - additionalInfo2Width / 2, 45); // Adjust Y position as needed
  
      // Add company information
      const companyName = name;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16); // Adjust the font size here
      const companyNameWidth = doc.getStringUnitWidth(companyName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const companyCIN = `CIN : ${cin}`;
      const Act = "(Incorporated under the Companies Act, 1956/2013)";
      const companyAddress = `Registered Office: ${address}, ${taluk}, ${district} - ${pincode}`;
      doc.text(companyName, centerX - companyNameWidth / 2, 55); // Adjust Y position as needed
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.text(companyCIN, centerX - (doc.getStringUnitWidth(companyCIN) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2, 65); // Adjust Y position as needed
      doc.text(Act, centerX - (doc.getStringUnitWidth(Act) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2, 70); // Adjust Y position as needed
      doc.text(companyAddress, centerX - (doc.getStringUnitWidth(companyAddress) * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2, 75);
  
      // Add certificate content
      const certificateContent = [
        "This is to certify that the person(s) named in this Certificate is/are the Registered Holder(s) of the within mentioned share(s) bearing", 
        "the distinctive number(s) herein specified in the above named Company subject to the Memorandum and Articles of",
        "Association of the Company and the amount endorsed herein has been paid up on each such share."
      ];
  
      let currentY = 85; // Define currentY variable
      const lineSpacing = 5; // Define lineSpacing variable
  
      certificateContent.forEach(line => {
        doc.text(line,  20, currentY);
        currentY += lineSpacing;
      });
  
      // Define font size for text within the boxes
      const boxFontSize = 10;
  
      // Continue adding remaining certificate content
      const remainingContent = [
        "Register Folio No: " + registerFolioNo +  "  Certificate No: " + certificateNo,
        "Name(s) of the Holder(s): " + holdersName,
        "No. of shares held: " + numberOfShares,
        "Distinctive No. (s): From " + distinctiveNos,
      ];
  
      // Draw rectangle around the lines with thin border lines
      const rect1X = 20;
      const rectY = 100; // Adjust Y position of the rectangle
      const rectWidth = 120; // Adjust width of the rectangle
      const rectHeight = 50; // Adjust height of the rectangle
      doc.setLineWidth(0.3); // Thin border
      doc.rect(rect1X, rectY, rectWidth, rectHeight);
  
      // Add text within the rectangle
      doc.setFontSize(boxFontSize);
      doc.text("EQUITY SHARES EACH OF (Nominal value) RUPEES " + equityShares , rect1X + 5, rectY + 21);
      doc.text("AMOUNT PAID-UP PER SHARE RUPEES " + amountPaidPerShare , rect1X + 5, rectY + 30);
  
      // Draw rectangle around the remaining content with thin border lines
      const rect2X = rect1X + rectWidth + 10; // Adjust X position to create space between rectangles
      // Calculate the height needed for the second rectangle based on the number of lines in the remaining content
      const remainingContentHeight = (remainingContent.length + 1) * 10; // Each line has a height of 10
      const rect2Height = Math.max(rectHeight, remainingContentHeight); // Set the height as the maximum between the default height and the content height
  
      doc.setLineWidth(0.3); // Thin border
      doc.rect(rect2X, rectY, rectWidth, rect2Height); // Use the calculated height for the second rectangle
  
      remainingContent.forEach((line, index) => {
        doc.text(line, rect2X + 5, rectY + 10 + (index * 10)); // Adjust X and Y position as needed
      });
  
      // Draw borders
      doc.setLineWidth(0.3); // Thin border
      doc.rect(5, 5, pageWidth - 10, doc.internal.pageSize.getHeight() - 10); // Outer border
      doc.setLineWidth(2); // Thick border
      doc.rect(7, 7, pageWidth - 14, doc.internal.pageSize.getHeight() - 14); // Inner border
  
      // Add remaining lines outside the border
      const remainingLines = [
        "Given under the common seal of the Company this " + formattedDate,
        "",
        "",
        "",
        "                                         ____________                                                                                                                                 ____________",
        "                                             " +authorisedsignatoryOne+"                                                                                                                                     " +authorisedsignatoryTwo+ " ",
        "",
        "Note: No transfer of the Share(s) comprised in the Certificate can be registered unless accompanied by this Certificate."
      ];
  
      remainingLines.forEach((line, index) => {
        doc.text(line, 20, rectY + rectHeight + 10 + (index * lineSpacing)); // Adjust X and Y position as needed
      });
  
      // Add next page with border
      doc.addPage();
      doc.setLineWidth(0.3); // Thin border
      doc.rect(5, 5, pageWidth - 10, doc.internal.pageSize.getHeight() - 10); // Outer border
      doc.setLineWidth(2); // Thick border
      doc.rect(7, 7, pageWidth - 14, doc.internal.pageSize.getHeight() - 14); // Inner border
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
  
      // Calculate centerX for centering the text
  const nextPageText = "MEMORANDUM OF TRANSFER OF SHARES MENTIONED OVERLEAF";
  const nextPageTextWidth = doc.getStringUnitWidth(nextPageText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  const centerXNextPageText = (pageWidth - nextPageTextWidth) / 2;

  doc.text(nextPageText, centerXNextPageText, 20);
  doc.setFontSize(11);
  // Add table
  const tableRows = [
      ["Date", "Transfer No.", "Registered Folio", "Names(S) of Transferee(S)", "Authorised Signatory"],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""],
      ["", "", "", "", ""]
  ];

  const startY = 30; // Adjust Y position to leave space after the text
  const rowHeight = 10; // Adjust the height of each row
  const colWidth = (pageWidth - 40) / 5; // Adjust column width based on the number of columns
  const borderWidth = 0.3; // Border width for the table cells

  tableRows.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
          const x = 20 + (colWidth * cellIndex);
          const y = startY + (rowHeight * rowIndex);
          // Draw border rectangle
          doc.setLineWidth(borderWidth);
          doc.rect(x, y, colWidth, rowHeight);
          // Add text inside the rectangle
          doc.text(cell, x + 2, y + (rowHeight / 2), { align: "left", valign: "middle" });
      });
  });

 


      // Save and download the PDF
      doc.save(`Certificate_${holdersName}.pdf`);
      
  
    } catch (error) {
      console.error("Error fetching certificate data:", error);
      alert("An error occurred while fetching the certificate data.");
    }
    fetchCertificateData();
  };
  


  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      {!showCertificate && (
        <>
          {loading && (
            <div
              style={{
                backdropFilter: 'blur(5px)',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Spinner
                thickness="4px"
                speed="0.5s"
                emptyColor="gray.200"
                color="teal"
                size="xl"
              />
            </div>
          )}
          <Center>
            <Heading as="h2" size="lg" mb="4">
              Profile Information
            </Heading>
          </Center>
          <Card>
            <Box display="flex">
              {/* Left Side */}
              <Box flex="1" p="4">
                <Box textAlign="center" fontWeight="bold" mb="4">
                  Basic Details
                </Box>
                <FormControl>
                  <FormLabel>Company Name</FormLabel>
                  <Input
                    value={userDetails.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" value={email} readOnly />
                </FormControl>
                <FormControl>
                  <FormLabel>GST Number</FormLabel>
                  <Input
                    value={userDetails.gstNumber}
                    onChange={(e) => handleChange('gstNumber', e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    value={userDetails.address}
                    onChange={(e) => handleChange('address', e.target.value)}
                  />
                </FormControl>
                <HStack spacing={4}>
                  <FormControl flex="1">
                    <FormLabel>Taluk</FormLabel>
                    <Input
                      value={userDetails.taluk}
                      onChange={(e) => handleChange('taluk', e.target.value)}
                    />
                  </FormControl>
                  <FormControl flex="1">
                    <FormLabel>District</FormLabel>
                    <Input
                      value={userDetails.district}
                      onChange={(e) => handleChange('district', e.target.value)}
                    />
                  </FormControl>
                </HStack>
                <HStack spacing={4}>
                  <FormControl flex="1">
                    <FormLabel>Pincode</FormLabel>
                    <Input
                      value={userDetails.pincode}
                      onChange={(e) => handleChange('pincode', e.target.value)}
                    />
                  </FormControl>
                  <FormControl flex="1">
                    <FormLabel>State</FormLabel>
                    <Input
                      value={userDetails.state}
                      onChange={(e) => handleChange('state', e.target.value)}
                    />
                  </FormControl>
                </HStack>
              </Box>

              {/* Right Side */}
              <Box flex="1" p="4">
                <Box textAlign="center" fontWeight="bold" mb="4">
                  Bank Details
                </Box>
                <FormControl>
                  <FormLabel>Account Number</FormLabel>
                  <Input
                    value={userDetails.bankDetails.accountNumber}
                    onChange={(e) =>
                      handleChange('bankDetails', {
                        ...userDetails.bankDetails,
                        accountNumber: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Bank Name</FormLabel>
                  <Input
                    value={userDetails.bankDetails.bankName}
                    onChange={(e) =>
                      handleChange('bankDetails', {
                        ...userDetails.bankDetails,
                        bankName: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>IFSC Code</FormLabel>
                  <Input
                    value={userDetails.bankDetails.ifscCode}
                    onChange={(e) =>
                      handleChange('bankDetails', {
                        ...userDetails.bankDetails,
                        ifscCode: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>CIN Number</FormLabel>
                  <Input
                    value={userDetails.bankDetails.cin}
                    onChange={(e) =>
                      handleChange('bankDetails', {
                        ...userDetails.bankDetails,
                        cin: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box textAlign="center">
              {isProfileFound ? (
                <Button
                  onClick={handleUpdate}
                  variant="outline"
                  colorScheme="teal"
                  mb="4"
                  mt="4"
                  mx="auto"
                  display="block"
                >
                  Update
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  mt="4"
                  mb="4"
                  mx="auto"
                  variant="solid"
                  colorScheme="teal"
                  display="block"
                >
                  Submit
                </Button>
              )}
              <Box
                as="button"
                bg="gray.200"
                p={6}
                borderRadius="lg"
                boxShadow="md"
                textAlign="center"
                cursor="pointer"
                transition="all 0.3s"
                _hover={{ transform: 'scale(1.05)' }}
                mb="4"
                mt="4"
                mx="auto"
                display="block"
                onClick={handleCertificate}
              >
                Certificate
              </Box>
            </Box>
          </Card>
        </>
      )}

{showCertificate && (
      <div>
        {/* Loading spinner specific to certificate section */}
        {loading && (
          <div
            style={{
              backdropFilter: 'blur(5px)',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999,
            }}
          >
            <Spinner
              thickness="4px"
              speed="0.5s"
              emptyColor="gray.200"
              color="teal"
              size="xl"
            />
          </div>
        )}
        <Center>
          <Heading as="h2" size="lg" mb="4">
            Certificate Information
          </Heading>
        </Center>
        <Flex justifyContent="space-between" mb={4}>
          <Button
            variant="solid"
            colorScheme="teal"
            leftIcon={<Icon as={FiArrowLeft} />}
            onClick={() => setShowCertificate(false)}
          >
            Back
          </Button>
          <Button
            variant="solid"
            colorScheme="teal"
            leftIcon={<Icon as={FiPlus} />}
            onClick={() => setIsOpen(true)}
          >
            New certificate
          </Button>
        </Flex>
        <Table variant="striped" colorScheme="teal" maxWidth="800px">
          <Thead>
            <Tr>
              <Th>Holders Name</Th>
              <Th>Equity Shares</Th>
              <Th>Per Share</Th>
              <Th>RegisterFolio No</Th>
              <Th>Certificate No</Th>
              <Th>Distinctive No</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody id="purchaseDataTable">
            {certificateData.map((certificate, index) => (
              <Tr key={index}>
                <Td>{certificate.holdersName}</Td>
                <Td>{certificate.equityShares}</Td>
                <Td>{certificate.amountPaidPerShare}</Td>
                 <Td>{certificate.registerFolioNo}</Td>
                 <Td>{certificate.certificateNo}</Td>
                 <Td>{certificate.distinctiveNos}</Td>
                <Td>
                  <Flex>
                  <Button
                          leftIcon={<Icon as={FiDownload} />}
                          colorScheme="teal"
                          variant="solid"
                          mr={2}
                          onClick={() => handleDownloadCertificate(certificate._id)}
                        >
                          Certificate
                        </Button>
                        <Button
  leftIcon={<Icon as={FiEdit} />}
  colorScheme="blue"
  variant="solid"
  mr={2}
  onClick={() => handleEdit(certificate)}
>
  Edit
</Button>

                    <Button
                      leftIcon={<Icon as={FiTrash2} />}
                      colorScheme="red"
                      variant="solid"
                      onClick={() => handleCertificateDelete(certificate._id)}
                    >
                      Delete
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    )}

      {/* Modal for Certificate Information */}
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Certificate Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>
                EQUITY SHARES EACH OF (Nominal value) RUPEES (Eg. 1500)
              </FormLabel>
              <Input
                value={certificateInfo.equityShares}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    equityShares: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>
              AMOUNT PAID-UP PER SHARE RUPEES (Eg. 1500)
              </FormLabel>
              <Input
                value={certificateInfo.amountPaidPerShare}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    amountPaidPerShare: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Register Folio No</FormLabel>
              <Input
                value={certificateInfo.registerFolioNo}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    registerFolioNo: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Certificate No</FormLabel>
              <Input
                value={certificateInfo.certificateNo}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    certificateNo: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Name(s) of the Holder(s)</FormLabel>
              <Input
                value={certificateInfo.holdersName}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    holdersName: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>No. of shares held (Eg. 01 (One))</FormLabel>
              <Input
                value={certificateInfo.numberOfShares}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    numberOfShares: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                Distinctive No. (s) (Eg. 01 To 01 (Both inclusive))
              </FormLabel>
              <Input
                value={certificateInfo.distinctiveNos}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    distinctiveNos: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Date (Eg. 25th day of January 2024)</FormLabel>
              <Input
                type="date"
                value={certificateInfo.date}
                onChange={handleDateChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Authorised signatory 1</FormLabel>
              <Input
                value={certificateInfo.authorisedsignatoryOne}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    authorisedsignatoryOne: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Authorised signatory 2</FormLabel>
              <Input
                value={certificateInfo.authorisedsignatoryTwo}
                onChange={(e) =>
                  setCertificateInfo((prevState) => ({
                    ...prevState,
                    authorisedsignatoryTwo: e.target.value,
                  }))
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleCertificateSubmit}>
              Save
            </Button>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Edit Certificate</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <FormControl>
        <FormLabel>EQUITY SHARES EACH OF (Nominal value) RUPEES</FormLabel>
        <Input
          value={editCertificateInfo?.equityShares}
          onChange={(e) =>
            setEditCertificateInfo((prevState) => ({
              ...prevState,
              equityShares: e.target.value,
            }))
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>AMOUNT PAID-UP PER SHARE RUPEES</FormLabel>
        <Input
          value={editCertificateInfo?.amountPaidPerShare}
          onChange={(e) =>
            setEditCertificateInfo((prevState) => ({
              ...prevState,
              amountPaidPerShare: e.target.value,
            }))
          }
        />
      </FormControl>
      <FormControl>
  <FormLabel>Register Folio No</FormLabel>
  <Input
    value={editCertificateInfo?.registerFolioNo}
    onChange={(e) =>
      setEditCertificateInfo((prevState) => ({
        ...prevState,
        registerFolioNo: e.target.value,
      }))
    }
  />
</FormControl>
<FormControl>
  <FormLabel>Certificate No</FormLabel>
  <Input
    value={editCertificateInfo?.certificateNo}
    onChange={(e) =>
      setEditCertificateInfo((prevState) => ({
        ...prevState,
        certificateNo: e.target.value,
      }))
    }
  />
</FormControl>
<FormControl>
  <FormLabel>Name(s) of the Holder(s)</FormLabel>
  <Input
    value={editCertificateInfo?.holdersName}
    onChange={(e) =>
      setEditCertificateInfo((prevState) => ({
        ...prevState,
        holdersName: e.target.value,
      }))
    }
  />
</FormControl>
<FormControl>
  <FormLabel>No. of shares held</FormLabel>
  <Input
    value={editCertificateInfo?.numberOfShares}
    onChange={(e) =>
      setEditCertificateInfo((prevState) => ({
        ...prevState,
        numberOfShares: e.target.value,
      }))
    }
  />
</FormControl>
<FormControl>
  <FormLabel>Distinctive No. (s)</FormLabel>
  <Input
    value={editCertificateInfo?.distinctiveNos}
    onChange={(e) =>
      setEditCertificateInfo((prevState) => ({
        ...prevState,
        distinctiveNos: e.target.value,
      }))
    }
  />
</FormControl>
<FormControl>
  <FormLabel>Date</FormLabel>
  <Input
    type="date"
    value={editCertificateInfo?.date}
    onChange={(e) =>
      setEditCertificateInfo((prevState) => ({
        ...prevState,
        date: e.target.value,
      }))
    }
  />
</FormControl>
<FormControl>
  <FormLabel>Authorised signatory 1</FormLabel>
  <Input
    value={editCertificateInfo?.authorisedsignatoryOne}
    onChange={(e) =>
      setEditCertificateInfo((prevState) => ({
        ...prevState,
        authorisedsignatoryOne: e.target.value,
      }))
    }
  />
</FormControl>
<FormControl>
  <FormLabel>Authorised signatory 2</FormLabel>
  <Input
    value={editCertificateInfo?.authorisedsignatoryTwo}
    onChange={(e) =>
      setEditCertificateInfo((prevState) => ({
        ...prevState,
        authorisedsignatoryTwo: e.target.value,
      }))
    }
  />
</FormControl>

    </ModalBody>
    <ModalFooter>
      <Button colorScheme="teal" mr={3} onClick={handleEditSubmit}>
        Save
      </Button>
      <Button variant="outline" onClick={() => setIsEditOpen(false)}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>

    </div>
  );
};

export default Profile;