import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useToast,Spinner, Table, Thead, Heading, Center,Text, Tbody, Tr,HStack,Card,CardBody, Th, Td, Button, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, Flex, Select, Alert } from '@chakra-ui/react';

import { FiPlus, FiEdit, FiTrash2, FiPaperclip, FiFilter } from 'react-icons/fi';
import { utils as XLSXUtils, writeFile as writeExcelFile } from 'xlsx';
import { baseURL } from '../baseURL.js';

import jsPDF from 'jspdf';


const Purchase = () => {
  const [purchaseData, setPurchaseData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    _id: '',
    supplier: '',
    invoiceNumber: '',
    products: [{ productName: '', quantity: '', unitPrice: '', gstTaxes: '', gstamount: '', taxableamount: '', }],
    total: 0,
    date: new Date().toISOString().slice(0, 10)
  });
   const [products, setProducts] = useState([]);
  const toast = useToast();

  const [productName, setProductName] = useState('');
  const [productRemoveName, setProductRemoveName] = useState('');
  const [showAddInput, setShowAddInput] = useState(false);
  const [showRemoveInput, setShowRemoveInput] = useState(false);

  const [email,setEmail] = useState();
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    gstNumber: '',
    address: '',
    taluk: '',
    district: '',
    pincode: '',
    state: '',
    bankDetails: { // Ensure proper nested structure for bankDetails
      bankName: '',
      accountNumber: '',
      ifscCode: ''
    }
  });
 
  const [loading, setLoading] = useState(true); // State to track loading status


  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  
  useEffect(() => {
    // Calculate one month ago from the current date
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    // Set start date to one month ago and end date to current date
    setStartDate(oneMonthAgo.toISOString().slice(0, 10));
    setEndDate(new Date().toISOString().slice(0, 10));
  
    fetchProducts();
    fetchPurchases();
    fetchUserProfile();
  }, []);
  
  
  const handleFilter = () => {
    setIsFilterModalOpen(true);
  };
  

  const handleFilterSubmit = () => {
    // Log start and end dates when filter is submitted
 

    fetchFilterPurchases()
    setIsFilterModalOpen(false);
  };
  const clearFormData = () => {
    setFormData({
        _id: '',
        supplier: '',
        invoiceNumber: '',
        products: [{ productName: '', quantity: '', unitPrice: '', gstTaxes: '', gstamount: '', taxableamount: '', }],
        total: '',
    });
    setProductName("");
    setProductRemoveName("");
    setShowAddInput(false);
    setShowRemoveInput(false);
};


const fetchUserProfile = async () => {
  try {
    const storedEmail = localStorage.getItem('email');
    setEmail(storedEmail);
    
    if (storedEmail) {
      const response = await axios.get(`${baseURL}/api/userprofiles?email=${storedEmail}`);
      setUserDetails(response.data.userDetails);
  
    } else {
      // Handle case where email is not stored
      console.error('Email not found in localStorage');
    }
  } catch (error) {
    console.error('Error fetching user profile:', error);
    if (error.response && error.response.data && error.response.data.message === "User profile not found") {

   
      if (!toast.isActive("profileNotFound")) {
        toast({
          id: "profileNotFound",
          title: "Your profile details were incomplete",
          description: "Please fill in your details.",
          status: "info",
          duration: 10000,
          isClosable: true,
          position: 'bottom-left'
        });
      }
    } else {
      toast({
        title: "Error",
        description: "An error occurred while fetching your profile. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }
};




  
  const fetchProducts = async (email) => {

    const storedEmail = localStorage.getItem('email');

    try {
        const response = await axios.get(`${baseURL}/api/products`, {
            params: {
                email: storedEmail, // Sending email as a query parameter
                // Other query parameters if needed
            }
        });
        setProducts(response.data);
    } catch (error) {
        console.error("Error fetching products:", error);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {

          if (!toast.isActive("profileNotFound")) {
            toast({
              id: "profileNotFound",
              title: "Please fill details on profile screen.",
              description: "Your profile details were incomplete",
              status: "info",
              duration: 10000,
              isClosable: true,
              position: 'bottom-left'
            });
          }
        }
    }
};




const handleNew = async () => {
  const storedEmail = localStorage.getItem('email');


    // Check if the entered product name already exists in the dropdown
    if (products.some(product => product.name === productName)) {
      // If the product name already exists, display a toast message
      toast({
        title: `${productName} already exists`,
        description: `Please select product on the products list.`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left'
      });
      return; // Exit the function
    }
  

  // Now you can send productName along with other data to your server
  try {
    const response = await axios.post(`${baseURL}/api/products`, {
      email: storedEmail,
      name: productName, // Include the productName in the request body
    });
    console.log(response.data);

    await fetchProducts();

    toast({
      id: "productNameAdded",
      title: "New product is added",
      description: "Your can now select from the products list",
      status: "info",
      duration: 10000,
      isClosable: true,
      position: 'bottom-left'
    });

    setShowAddInput(!showAddInput)


    setProductName('');

  } catch (error) {
    console.error('Error creating product:', error);
  }
};

const handleOld = async () => {



  console.log("JHVIV")
  const storedEmail = localStorage.getItem('email');

  // Check if the entered product name already exists in the dropdown
  if (!products.some(product => product.name === productRemoveName)) {
    // If the product name already exists, display a toast message
    toast({
      title: `${productRemoveName} not exists`,
      description: `Please enter valid product name.`,
      status: 'warning',
      duration: 5000,
      isClosable: true,
      position: 'bottom-left'
    });
    return; // Exit the function
  }
  

  // Now you can send productName along with other data to your server
  try {
    const response = await axios.delete(`${baseURL}/api/products`, {
      data: {
        email: storedEmail,
        name: productRemoveName,
      },// Include the productName in the request body
    });

    await fetchProducts();

    toast({
      id: "productNameRemoved",
      title: "Product is removed",
      description: "Your product removed from the products list",
      status: "info",
      duration: 10000,
      isClosable: true,
      position: 'bottom-left'
    });

    setShowRemoveInput(!showRemoveInput);
    setProductRemoveName('');

  } catch (error) {
    console.error('Error creating product:', error);
  }
};


const fetchFilterPurchases = async () => {
  setLoading(true);
  const storedEmail = localStorage.getItem('email');
  try {
  

      const response = await axios.get(`${baseURL}/api/purchases`, {
          params: {
              email: storedEmail,
              startDate: startDate,
              endDate: endDate,
          }
      });
      setPurchaseData(response.data);
      setLoading(false);
  } catch (error) {
      console.error("Error fetching purchases:", error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {
          if (!toast.isActive("profileNotFound")) {
              toast({
                  id: "profileNotFound",
                  title: "Please fill details on profile screen.",
                  description: "Your profile details were incomplete",
                  status: "info",
                  duration: 10000,
                  isClosable: true,
                  position: 'bottom-left'
              });
          }
      }
  }
};

  
const fetchPurchases = async () => {
  const storedEmail = localStorage.getItem('email');


  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  

  
  try {
  

      const response = await axios.get(`${baseURL}/api/purchases`, {
          params: {
              email: storedEmail,
              startDate: oneMonthAgo.toISOString().slice(0, 10), // Pass formatted start date as query parameter
              endDate: new Date().toISOString().slice(0, 10) // Pass formatted end date as query parameter
          }
      });
      setPurchaseData(response.data);
      setLoading(false);
  } catch (error) {
      console.error("Error fetching purchases:", error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {
          if (!toast.isActive("profileNotFound")) {
              toast({
                  id: "profileNotFound",
                  title: "Please fill details on profile screen.",
                  description: "Your profile details were incomplete",
                  status: "info",
                  duration: 10000,
                  isClosable: true,
                  position: 'bottom-left'
              });
          }
      }
  }
};


const handleAddData = () => {
  // Set the default date to the current date
  const currentDate = new Date().toISOString().slice(0, 10);
  setFormData(prevState => ({
    ...prevState,
    date: currentDate
  }));
  setIsModalOpen(true);
};


  const handleEdit = (id) => {
    const itemToEdit = purchaseData.find(item => item._id === id);
    const { _id, supplier, invoiceNumber, products, total, date, gstamount, taxableamount } = itemToEdit;
    
    // Extract tax values for each product and convert them to strings
    const gstTaxes = products.map(product => product.gstTaxes.toString());
    
    // Set the form data with taxes included for each product
    setFormData({ _id, supplier, invoiceNumber, products, total, date, gstTaxes, gstamount, taxableamount });
    setIsModalOpen(true);
  };
  
  
  

  const handleSubmit = async () => {

    formData.gstTaxes = formData.gstTaxes === "" ? 0 : formData.gstTaxes;

    console.log(formData.gstTaxes)

   
    if (!formData.supplier || !formData.invoiceNumber || !formData.products.length || formData.total  <= 0 ) {
      toast({
        title: "Please fill all details",
        description: "Required Fields Missing",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: 'bottom-left'
    });
      return null; // Optionally return null or some placeholder UI
  }
    try {
      // Retrieve email from localStorage
      const storedEmail = localStorage.getItem('email');

      if (formData._id) {
          // Edit existing purchase entry
          await axios.put(`${baseURL}/api/purchases/${formData._id}`, formData, {
              params: {
                  email: storedEmail, // Sending email as a query parameter
              }
          });

          toast({
            title: "Purchase entry updated",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left'
        });
      } else {
          // Add new purchase entry
          await axios.post(`${baseURL}/api/purchases`, formData, {
              params: {
                  email: storedEmail, // Sending email as a query parameter
              }
          });

          toast({
            title: "New purchase entry added",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left'
        });
      }
      fetchPurchases(); // Refresh purchase list after adding/editing
  } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
  }
    // Reset form data and close modal
    setFormData({ _id: '', supplier: '', invoiceNumber: '', products: [{ productName: '', quantity: '', unitPrice: '', gstTaxes: '', gstamount: '', taxableamount: ''  }], total: ''});
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };
    
    // Recalculate total if the tax value changes
    if (name === 'gstTaxes') {
      updatedFormData = { ...updatedFormData, total: calculateTotal({ ...formData, [name]: value }) };
    }
  
    setFormData(updatedFormData);
  };
  
  

  const handleDateChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, date: value });
  };


  const handleProductChange = (e, index) => {
    const { name, value } = e.target;
    const updatedProducts = formData.products.map((product, idx) => {
      if (idx === index) {
        return { ...product, [name]: value };
      }
      return product;
    });
  
    setFormData(prevFormData => {
      const updatedFormData = { ...prevFormData, products: updatedProducts };
      const total = calculateTotal(updatedFormData);
      return { ...updatedFormData, total };
    });
  };
  

  const calculateTotal = (formData) => {
    const total = formData.products.reduce((acc, curr) => {
      const { quantity, unitPrice, gstTaxes } = curr;
      const totalWithoutTax = unitPrice * quantity;
      const taxAmount = (totalWithoutTax * gstTaxes) / 100;
      const totalWithTax = totalWithoutTax + taxAmount;
      console.log("Taxable Amount"+totalWithoutTax)
      console.log("GST Amount"+taxAmount)
      return acc + totalWithTax;
    }, 0);
    
    return total.toFixed(2); // Return total with tax
  };
  
  
  const handleAddProduct = () => {
    const newProduct = { productName: '', quantity: '', unitPrice: '', gstTaxes: '', gstamount: '', taxableamount: '' };
    setFormData({ ...formData, products: [...formData.products, newProduct] });
  };



  const handleDeleteProduct = (index) => {
    const updatedProducts = [...formData.products];
    updatedProducts.splice(index, 1);
    
    const updatedFormData = {
        ...formData,
        products: updatedProducts,
        total: calculateTotal({ ...formData, products: updatedProducts }) // Recalculate total
    };

    setFormData(updatedFormData);
};


  const handleDelete = async (id) => {


    try {
        // Retrieve email from localStorage
        const storedEmail = localStorage.getItem('email');

        // Delete the purchase entry with the given id
        await axios.delete(`${baseURL}/api/purchases/${id}`, {
            params: {
                email: storedEmail, // Sending email as a query parameter
            }
        });

        toast({
          title: "Purchase entry deleted",
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left'
      });
        fetchPurchases(); // Refresh purchase list after deletion
    } catch (error) {
        console.error("Error deleting purchase:", error);
    }
};

const exportToPDF = () => {
  let totalSum = 0; // Variable to store the sum of purchase totals

  const data = purchaseData.map((purchase) => {
    // Calculate the total for each purchase and add it to totalSum
    const purchaseTotal = parseFloat(purchase.total); // Parse total to float
    totalSum += purchaseTotal;

    return [
      new Date(purchase.date).toLocaleDateString(),
      purchase.supplier,
      purchase.invoiceNumber,
      purchase.products.map((product) => product.productName).join('\n'),
      purchase.products.map((product) => product.quantity).join('\n'),
      purchase.products.map((product) => product.unitPrice).join('\n'),
      purchase.products.map((product) => product.taxableAmount).join('\n'),
      purchase.products.map((product) => product.gstTaxes).join('\n'),
      purchase.products.map((product) => product.gstAmount).join('\n'),
      purchase.total
    ];
  });

  const headers = [
    ['Date', 'Supplier', 'Invoice Number', 'Products', 'Quantity', 'Rate/Unit','Taxable Amount', 'GST Taxes (%)', 'GST Amount',  'Total']
  ];

  const pdf = new jsPDF();
  
  // Add Purchase Information heading
  pdf.setFontSize(18);
  pdf.text(`Purchase Information from ${startDate} till ${endDate}`, 14, 20);

  // Generate autoTable with adjusted font size for the table content
  pdf.autoTable({
    head: headers,
    body: data,
    startY: 30, // Adjust the starting Y position as needed
    theme: 'grid',
    headStyles: {
      fillColor: [211, 211, 211], // Set heading background color to light gray (RGB: 211, 211, 211)
      textColor: [0, 0, 0] // Set heading text color to black
    },
    styles: {
      lineColor: [0, 0, 0], // Set line color to black
      textColor: [0, 0, 0], // Set text color to black
      fontStyle: 'normal', // Set font style to normal
      lineWidth: 0.1, // Set line width
      fontSize: 6 // Set the font size for table content
    }
  });

  // Display the total sum of purchases at the end
  pdf.setFontSize(12);
  pdf.text(`Total Sum: ${totalSum.toFixed(2)}`, 14, pdf.lastAutoTable.finalY + 10);

  pdf.save('purchases.pdf');
};

const exportToJSON = () => {
  // Remove _id from products array in purchaseData
  const dataWithoutIdInProducts = purchaseData.map(({ products, ...rest }) => ({
      ...rest,
      products: products.map(({ _id, ...product }) => product)
  }));

  // Remove _id, email, and __v fields from purchaseData
  const dataWithoutIdEmailAndV = dataWithoutIdInProducts.map(({ _id, email, __v, ...rest }) => rest);

  // Format the date to DD-MM-YYYY and extract it for the file name
  const formattedData = dataWithoutIdEmailAndV.map(data => ({
    ...data,
    date: new Date(data.date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) // DD-MM-YYYY
  }));

  // Get the date from the first entry in the data
  const firstEntryDate = formattedData.length > 0 ? formattedData[0].date : 'unknown_date';

  const jsonData = JSON.stringify(formattedData, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `purchases_${firstEntryDate}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const exportToExcel = () => {

  console.log(purchaseData)
  // Prepare data for export and change date format
  const dataForExport = purchaseData.map(({ supplier, invoiceNumber, total, date, products }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }); // DD-MM-YYYY

    const productDetails = products.reduce((acc, product, index) => {
      return {
        ...acc,
        [`Product Name ${index + 1}`]: product.productName,
        [`Quantity ${index + 1}`]: product.quantity,
        [`Rate/Unit ${index + 1}`]: product.unitPrice,
        [`GST % ${index + 1}`]: product.gstTaxes,
        [`GST Amount ${index + 1}`]: product.gstAmount, 
        [`Taxable Amount ${index + 1}`]: product.taxableAmount 
      };
    }, {});

    return {
      'Supplier': supplier,
      'Invoice Number': invoiceNumber,
      'Date': formattedDate,
      'Total': total,
      ...productDetails
    };
  });

  // Convert data to worksheet
  const ws = XLSXUtils.json_to_sheet(dataForExport);

  // Create workbook and add worksheet
  const wb = XLSXUtils.book_new();
  XLSXUtils.book_append_sheet(wb, ws, "Purchases");

  // Save workbook as Excel file
  writeExcelFile(wb, "purchases.xlsx");
};


  return (
    <>         
       <div style={{ position: 'relative' }}>
     {loading && ( // Show blur effect and loading icon if data is being fetched
                  <div style={{ backdropFilter: 'blur(5px)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
                  <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="teal" size="xl" />
              </div>
                )}
                   <Center>
      <Heading as="h2" size="lg" mb="4">
      Purchase Information
      </Heading>
    </Center>
  <Flex justifyContent="space-between" mb={4}>
        <Button
            variant="outline"
            colorScheme="teal"
            leftIcon={<Icon as={FiPaperclip} />}
            onClick={exportToExcel}
        >
            Export to Excel
        </Button>
        <Button
            variant="outline"
            colorScheme="teal"
            leftIcon={<Icon as={FiPaperclip} />}
            onClick={exportToJSON}
        >
            Export to JSON
        </Button>
        <Button
  variant="outline"
  colorScheme="teal"
  leftIcon={<Icon as={FiPaperclip} />}
  onClick={exportToPDF}
>
  Export to PDF
</Button>

        <Button
    variant="outline"
    colorScheme="teal"
    leftIcon={<Icon as={FiFilter} />}
    onClick={handleFilter}
>
    Filter
</Button>
     
        <Button
            variant="solid"
            colorScheme="teal"
            leftIcon={<Icon as={FiPlus} />}
            onClick={handleAddData}
        >
            New purchase
        </Button>
    </Flex>
      <Table variant="striped" colorScheme="teal" maxWidth="800px">
  <Thead>
    <Tr>
      <Th>Date</Th>
      <Th>Supplier</Th>
      <Th>Invoice Number</Th>
      <Th>Product</Th>
      <Th>Quantity</Th>
      <Th>Rate/Unit</Th>
      <Th>Taxable Amount</Th>
      <Th>GST%</Th>
      <Th>GST Amount</Th>
      <Th>Amount</Th>
      <Th>Action</Th>
    </Tr>
  </Thead>
  <Tbody id="purchaseDataTable">
  {purchaseData.slice().reverse().map((purchase, index) => (
    <Tr key={purchase._id || index}>
      <Td>{new Date(purchase.date).toLocaleDateString()}</Td> {/* Format the date here */}
    
      <Td>{purchase.supplier}</Td>
      <Td>{purchase.invoiceNumber}</Td>
      <Td>
        {purchase.products.length > 0 ? (
          purchase.products.map((product, idx) => (
            <div key={idx}>
              <div>{product.productName}</div>
            </div>
          ))
        ) : (
          <div>No Products Available</div>
        )}
      </Td>
      <Td>
        {purchase.products.length > 0 ? (
          purchase.products.map((product, idx) => (
            <div key={idx}>
              <div>{product.quantity}</div>
            </div>
          ))
        ) : (
          <div>No Quantity Available</div>
        )}
      </Td>
      <Td>
        {purchase.products.length > 0 ? (
          purchase.products.map((product, idx) => (
            <div key={idx}>
              <div>{product.unitPrice}</div>
            </div>
          ))
        ) : (
          <div>No Rate/Unit Available</div>
        )}
      </Td>
      <Td>
        {purchase.products.length > 0 ? (
          purchase.products.map((product, idx) => (
            <div key={idx}>
              <div>{product.taxableAmount}</div>
            </div>
          ))
        ) : (
          <div>No Taxable Amount Available</div>
        )}
      </Td>
      <Td>
        {purchase.products.length > 0 ? (
          purchase.products.map((product, idx) => (
            <div key={idx}>
              <div>{product.gstTaxes}%</div>
            </div>
          ))
        ) : (
          <div>No Taxes Available</div>
        )}
      </Td>
      <Td>
        {purchase.products.length > 0 ? (
          purchase.products.map((product, idx) => (
            <div key={idx}>
              <div>{product.gstAmount}</div>
            </div>
          ))
        ) : (
          <div>No GST Amount Available</div>
        )}
      </Td>
      <Td>{purchase.total}</Td>
      <Td>
      <Flex>
        <Button   leftIcon={<Icon as={FiEdit} />}
                          colorScheme="blue"
                          variant="solid"
                          mr={2}onClick={() => handleEdit(purchase._id)}>Edit</Button>
        <Button  leftIcon={<Icon as={FiTrash2} />}
                          colorScheme="red"
                          variant="solid" onClick={() => handleDelete(purchase._id)}>Delete</Button>
                              </Flex>
      </Td>
    </Tr>
  ))}
</Tbody>

</Table>

<Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </FormControl>
   
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleFilterSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() => setIsFilterModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isModalOpen} onClose={() => { 
    setIsModalOpen(false); 
    // Call your function here
    clearFormData();
}}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add/Edit Purchase Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Supplier</FormLabel>
              <Input type="text" name="supplier" value={formData.supplier} onChange={handleChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input type="date" name="date" value={formData.date} onChange={handleDateChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Invoice Number</FormLabel>
              <Input type="text" name="invoiceNumber" value={formData.invoiceNumber} onChange={handleChange} />
            </FormControl>
            {formData.products.map((product, index) => (
              <Card mt={4}>
                <CardBody>
              <div key={index}>
                <FormControl>
                  <FormLabel>Product Name</FormLabel>
                  <Flex alignItems="center">
                  <Select
  name="productName"
  value={formData.products[index].productName} // Use product name from formData
  onChange={(e) => handleProductChange(e, index)}
>
  <option value="">Select Product</option>
  {products
    .filter(
      (prod) =>
        !formData.products
          .slice(0, index)
          .map((item) => item.productName)
          .includes(prod.name)
    )
    .map((product) => (
      <option key={product._id} value={product.name}>
        {product.name}
      </option>
    ))}
</Select>
<Button mt={2} mb={2} ml={2} onClick={() => setShowAddInput(!showAddInput)}>
        {showAddInput ? '+' : '+'}
      </Button>
      <Button mt={2} mb={2} ml={2} onClick={() => setShowRemoveInput(!showRemoveInput)}>
        {showRemoveInput ? '-' : '-'}
      </Button>

</Flex>


                </FormControl>
                <FormControl>
                  <FormLabel>Quantity</FormLabel>
                  <Input
                    type="number"
                    name="quantity"
                    value={product.quantity}
                    onChange={(e) => handleProductChange(e, index)}
                    min={1}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Rate/Unit Price</FormLabel>
                  <Input
                    type="number"
                    name="unitPrice"
                    value={product.unitPrice}
                    onChange={(e) => handleProductChange(e, index)}
                    min={0}
                    step="0.01"
                  />
                </FormControl>

                <FormControl>
              <FormLabel>Taxes (%)</FormLabel>
              <Select
  name="gstTaxes"
  value={product.gstTaxes}
  onChange={(e) => handleProductChange(e, index)}
>
  <option value="">Select Tax Rate</option>
  <option value="0.0">Null</option>
  <option value="0">0%</option>
  <option value="5">5%</option>
  <option value="12">12%</option>
  <option value="18">18%</option>
  <option value="28">28%</option>
</Select>

            </FormControl>


                <Button variant="ghost" colorScheme="red" onClick={(e) => handleDeleteProduct(index) }>Remove</Button>
              </div>
              </CardBody>
              </Card>
            ))}

<Flex justify="center">
        <Button mt={2} mb={2} mr={2} colorScheme="teal" onClick={handleAddProduct}>Add another product</Button>
       
    
      </Flex>
      {showAddInput && (
        <HStack spacing={2}>
          <Input
            type="text"
            placeholder="New product name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
          <Button ml={2} onClick={handleNew}>
            Add
          </Button>
        </HStack>
      )}

{showRemoveInput && (
        <HStack spacing={2} mt={2}>
          <Input
            type="text"
            placeholder="Existing product name"
            value={productRemoveName}
            onChange={(e) => setProductRemoveName(e.target.value)}
          />
          <Button ml={2} onClick={handleOld}>
            Remove
          </Button>
        </HStack>
      )}
         
            <FormControl>

    <Text textAlign="center" fontSize="2xl">{`Total: ${isNaN(formData.total) ? 0 : formData.total} Rs`}</Text>

</FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() =>   {setIsModalOpen(false); 
    clearFormData();}}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </div>
      </>
  );
};

export default Purchase;