import React, { useState, useEffect } from 'react';
import { useToast, Flex, Text, Icon, Box, Table,Spinner,Center,Heading, Thead, Tbody,Radio, RadioGroup, Tr, Th, Td, Button, Input, Select, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { FiArrowLeft, FiPlus, FiEdit, FiTrash2,FiFilter,FiPaperclip } from 'react-icons/fi';
import { GiMoneyStack } from "react-icons/gi";
import * as XLSX from 'xlsx'; // Import XLSX library
import { baseURL } from '../baseURL.js';
import axios from 'axios';

import jsPDF from 'jspdf';

const Expenses = () => {
  const [showDirectExpenses, setShowDirectExpenses] = useState(false);
  const [showIndirectExpenses, setShowIndirectExpenses] = useState(false);
  const [directExpensesData, setDirectExpensesData] = useState([]);
  const [indirectExpensesData, setIndirectExpensesData] = useState([]);
  const [formData, setFormData] = useState({ category: '', date: '', name: '', description: '', price: '', paymentMethod: '',  referenceNumber: '', });
  const [showDirectFormModal, setShowDirectFormModal] = useState(false);
  const [showIndirectFormModal, setShowIndirectFormModal] = useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = useState(null);
  const toast = useToast();

  const categories = ['Rent', 'Utilities', 'Salaries', 'Supplies', 'Others'];


  const [loading, setLoading] = useState(true); // Add loading state


    
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    // Set start date to one month ago and end date to current date
    setStartDate(oneMonthAgo.toISOString().slice(0, 10));
    setEndDate(new Date().toISOString().slice(0, 10));


    fetchDirectExpenses();
    fetchIndirectExpenses();
  }, []);


  const handleFilter = () => {
    setIsFilterModalOpen(true);
  };
  

  const handleFilterSubmit = () => {
    // Log start and end dates when filter is submitted
 


    fetchFilterDirectExpenses()
    fetchFilterIndirectExpenses()
    setIsFilterModalOpen(false);
  };



  const clearFormData = () => {
    setFormData({
        category: '',
        date: '',
        name: '',
        description: '',
        price: '',
        paymentMethod: '',
        referenceNumber: '',
    });
};



  const fetchFilterDirectExpenses = async () => {
    try {

        const storedEmail = localStorage.getItem('email');

        const response = await axios.get(`${baseURL}/api/direct-expenses`, {
          params: {
              email: storedEmail, // Sending email as a query parameter
              startDate: startDate, // Sending start date as a query parameter
              endDate: endDate // Sending end date as a query parameter
          }
      });
  
       
        setDirectExpensesData(response.data);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching direct expenses:', error);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {

          if (!toast.isActive("profileNotFound")) {
            toast({
              id: "profileNotFound",
              title: "Please fill details on profile screen.",
              description: "Your profile details were incomplete",
              status: "info",
              duration: 10000,
              isClosable: true,
              position: 'bottom-left'
            });
          }
        }
        
    }
};


const fetchDirectExpenses = async () => {
  try {

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const storedEmail = localStorage.getItem('email');


      const response = await axios.get(`${baseURL}/api/direct-expenses`, {
        params: {
            email: storedEmail, // Sending email as a query parameter
            startDate: oneMonthAgo.toISOString().slice(0, 10), // Pass formatted start date as query parameter
            endDate: new Date().toISOString().slice(0, 10) // Pass formatted end date as query parameter
         }
    });

     
      setDirectExpensesData(response.data);
      setLoading(false);
  } catch (error) {
      console.error('Error fetching direct expenses:', error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
  }
};


const fetchFilterIndirectExpenses = async () => {
    try {
        // Retrieve email from localStorage
        const storedEmail = localStorage.getItem('email');

  

        const response = await axios.get(`${baseURL}/api/indirect-expenses`, {
          params: {
              email: storedEmail, // Sending email as a query parameter
              startDate: startDate, // Sending start date as a query parameter
              endDate: endDate // Sending end date as a query parameter
             }
      });
  
    
        setIndirectExpensesData(response.data);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching indirect expenses:', error);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {

          if (!toast.isActive("profileNotFound")) {
            toast({
              id: "profileNotFound",
              title: "Please fill details on profile screen.",
              description: "Your profile details were incomplete",
              status: "info",
              duration: 10000,
              isClosable: true,
              position: 'bottom-left'
            });
          }
        }
        
    }
};



const fetchIndirectExpenses = async () => {
  try {
      // Retrieve email from localStorage
      const storedEmail = localStorage.getItem('email');
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      const response = await axios.get(`${baseURL}/api/indirect-expenses`, {
        params: {
            email: storedEmail, // Sending email as a query parameter
            startDate: oneMonthAgo.toISOString().slice(0, 10), // Pass formatted start date as query parameter
            endDate: new Date().toISOString().slice(0, 10) // Pass formatted end date as query parameter
        }
    });

  
      setIndirectExpensesData(response.data);
      setLoading(false);
  } catch (error) {
      console.error('Error fetching indirect expenses:', error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
  }
};




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddDirectExpense = () => {
    setShowDirectFormModal(true);
  };

  const handleAddIndirectExpense = () => {
    setShowIndirectFormModal(true);
  };


  const handleSubmitDirectExpense = async () => {
    // Retrieve email from localStorage
    const userEmail = localStorage.getItem('email');

    // Prepare request data with the user's email
    const requestData = { ...formData, email: userEmail };

    // Inside the if-else block
    if (selectedExpenseId) {
        axios
            .put(`${baseURL}/api/direct-expenses/${selectedExpenseId}`, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (response.ok === 200) {
                    toast({
                        title: 'Direct expense updated successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'bottom-left'
                    });
                    setSelectedExpenseId(null);
                } 
            })
            .catch(error => {
                console.error('Error updating direct expense:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to update direct expense. Please try again later.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom-left'
                });
            });
    }
    
    if (!selectedExpenseId) {
        axios
            .post(`${baseURL}/api/direct-expenses`, requestData)
            .then(response => {
                    toast({
                        title: 'Direct expense added successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'bottom-left'
                    });
                    fetchDirectExpenses();
                }) 

                .catch(error => {
                console.error('Error adding direct expense:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to add direct expense. Please try again later.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom-left'
                });
              });
    }
  
    // Reset form data and close modal
    setFormData({ date: '', name: '', description: '', price: '', paymentMethod: '', referenceNumber: '' });
    setShowDirectFormModal(false);
    fetchDirectExpenses(); // Refetch direct expenses data
};



const handleSubmitIndirectExpense = async () => {
  
  try {
      // Retrieve the email from localStorage
      const storedEmail = localStorage.getItem('email');

      // Add the email field to the formData
      const formDataWithEmail = { ...formData, email: storedEmail };


// Inside the if-else block
if (selectedExpenseId) {
  try {
      const response = await axios.put(`${baseURL}/api/indirect-expenses/${selectedExpenseId}`, formDataWithEmail, {
          headers: {
              'Content-Type': 'application/json',
          },
      });
      if (response.status !== 200) {
          throw new Error('Failed to update indirect expense');
      }
      if (response.status == 200){
      toast({
        title: 'Indirect expense updated successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom-left'
    });
  }
      setSelectedExpenseId(null);
  } catch (error) {
      console.error('Error updating indirect expense:', error);
  }
} 

if (!selectedExpenseId)  {



    axios.post(`${baseURL}/api/indirect-expenses`, formDataWithEmail,)
        .then(response => {
                toast({
                    title: 'Indirect expense added successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom-left'
                });
                fetchIndirectExpenses();
            }) 
            .catch(error => {
            console.error('Error adding indirect expense:', error);
            toast({
                title: 'Error',
                description: 'Failed to add indirect expense. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'bottom-left'
            });
          });
}





      setFormData({ date: '', name: '', description: '', price: '', paymentMethod: '', referenceNumber: '' });
      setShowIndirectFormModal(false);
      fetchIndirectExpenses();
  } catch (error) {
      console.error('Error handling indirect expense submission:', error);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
  }
};

  const handleEdit = (id) => {
    const selectedDirectExpense = directExpensesData.find(expense => expense._id === id);
    const selectedIndirectExpense = indirectExpensesData.find(expense => expense._id === id);
  
    if (selectedDirectExpense) {
      setFormData(selectedDirectExpense);
      setShowDirectFormModal(true);
    } else if (selectedIndirectExpense) {
      setFormData(selectedIndirectExpense);
      setShowIndirectFormModal(true);
    }
  
    setSelectedExpenseId(id);
  };


  const handleDelete = async (id) => {
      try {
          // Retrieve email from localStorage
          const storedEmail = localStorage.getItem('email');
  
          // Define request options
          const requestOptions = {
              headers: {
                  'Content-Type': 'application/json',
              },
              data: { email: storedEmail }, // Include email in the request body
          };
  
          // Delete direct and indirect expenses using Axios
          const [directResponse, indirectResponse] = await Promise.all([
              axios.delete(`${baseURL}/api/direct-expenses/${id}`, requestOptions),
              axios.delete(`${baseURL}/api/indirect-expenses/${id}`, requestOptions)
          ]);
  
          // Check if any of the responses is not successful
          if (directResponse.status !== 200 || indirectResponse.status !== 200) {
              throw new Error('Failed to delete expenses');
          }

          toast({
            title: 'Indirect expense deleted successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'bottom-left'
        });
  
          // Fetch updated direct and indirect expenses
          fetchDirectExpenses();
          fetchIndirectExpenses();
      } catch (error) {
          console.error('Error deleting expenses:', error);
      }
  };
  

  const handleExportDirectExpensesToExcel = () => {
    // Prepare data for export and change date format
    const formattedData = directExpensesData.map(({ _id, __v, email, date, ...expense }) => {
      const formattedDate = new Date(date).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      return { ...expense, date: formattedDate };
    });
  
    // Get the first entry date in directExpensesData
    const firstEntryDate = directExpensesData.length > 0 ? new Date(directExpensesData[0].date).toLocaleDateString('en-GB') : 'unknown_date';
  
    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(formattedData);
  
    // Create workbook and add worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Expenses");
  
    // Save workbook as Excel file with the first entry date in the file name
    XLSX.writeFile(wb, `Direct_Expenses_${firstEntryDate}.xlsx`);
  };
  

const handleExportIndirectExpensesToExcel = () => {
  // Prepare data for export and change date format
  const formattedData = indirectExpensesData.map(({ _id, __v, email, date, ...expense }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return { ...expense, date: formattedDate };
  });

  // Get the first entry date in indirectExpensesData
  const firstEntryDate = indirectExpensesData.length > 0 ? new Date(indirectExpensesData[0].date).toLocaleDateString('en-GB') : 'unknown_date';

  // Convert data to worksheet
  const ws = XLSX.utils.json_to_sheet(formattedData);

  // Create workbook and add worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Indirect Expenses");

  // Save workbook as Excel file with the first entry date in the file name
  XLSX.writeFile(wb, `Indirect_Expenses_${firstEntryDate}.xlsx`);
};



  // Function to export direct expenses to JSON
const handleExportDirectExpensesToJSON = () => {
  const jsonData = JSON.stringify(directExpensesData.map(({ _id, __v, email, ...expense }) => {
    // Convert the date to the desired format
    const formattedDate = new Date(expense.date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    // Remove keys with empty values
    Object.keys(expense).forEach(key => (expense[key] === '' || expense[key] === null) && delete expense[key]);
    // Return the expense object with the formatted date
    return { ...expense, date: formattedDate };
  }));
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);

  // Get the date from the first entry in directExpensesData
  const firstEntryDate = directExpensesData.length > 0 ? new Date(directExpensesData[0].date).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }) : 'unknown_date';

  const a = document.createElement('a');
  a.href = url;
  // Include firstEntryDate in the file name
  a.download = `direct_expenses_data_${firstEntryDate}.json`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Function to export indirect expenses to JSON
const handleExportIndirectExpensesToJSON = () => {
  const jsonData = JSON.stringify(indirectExpensesData.map(({ _id, __v, email, ...expense }) => {
    // Convert the date to the desired format
    const formattedDate = new Date(expense.date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    // Remove keys with empty values
    Object.keys(expense).forEach(key => (expense[key] === '' || expense[key] === null) && delete expense[key]);
    // Return the expense object with the formatted date
    return { ...expense, date: formattedDate };
  }));
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);

  // Get the date from the first entry in indirectExpensesData
  const firstEntryDate = indirectExpensesData.length > 0 ? new Date(indirectExpensesData[0].date).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }) : 'unknown_date';

  const a = document.createElement('a');
  a.href = url;
  // Include firstEntryDate in the file name
  a.download = `indirect_expenses_data_${firstEntryDate}.json`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const exportExpensesToPDF = () => {
  const expensesData = showDirectExpenses ? directExpensesData : indirectExpensesData;
  let totalSum = 0; // Variable to store the sum of expenses prices

  const data = expensesData.map((expense) => {
    totalSum += expense.price; // Add the price of each expense to the total sum

    return [
      new Date(expense.date).toLocaleDateString(),
      expense.referenceNumber, // Include the reference number
      expense.name,
      expense.description,
      expense.price,
      expense.paymentMethod
    ];
  });

  const headers = [
    ['Date', 'Reference Number', 'Name', 'Description', 'Price', 'Payment Method']
  ];

  const pdf = new jsPDF();
  
  // Add Expenses Information heading
  pdf.setFontSize(18);
  pdf.text(`${showDirectExpenses ? 'Direct' : 'Indirect'} Expenses Information from ${startDate} till ${endDate}`, 14, 20);

  // Generate autoTable
  pdf.autoTable({
    head: headers,
    body: data,
    startY: 30, // Adjust the starting Y position as needed
    theme: 'grid',
    headStyles: {
      fillColor: [211, 211, 211], // Set heading background color to light gray (RGB: 211, 211, 211)
      textColor: [0, 0, 0] // Set heading text color to black
    },
    styles: {
      lineColor: [0, 0, 0], // Set line color to black
      textColor: [0, 0, 0], // Set text color to black
      fontStyle: 'normal', // Set font style to normal
      lineWidth: 0.1 // Set line width
    }
  });

  // Display the total sum of expenses at the end
  pdf.setFontSize(12);
  pdf.text(`Total Sum: ${totalSum.toFixed(2)}`, 14, pdf.lastAutoTable.finalY + 10);

  pdf.save(`${showDirectExpenses ? 'direct' : 'indirect'}_expenses.pdf`);
};

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
    {loading && (
      <div style={{ backdropFilter: 'blur(5px)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
        <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="teal" size="xl" />
      </div>
    )}
    <>
      {!showDirectExpenses && !showIndirectExpenses && (
        <>
          <Text fontSize="3xl" fontWeight="bold" mb={4}>
            Expenses
          </Text>
          <Flex justifyContent="center">
            <Box
              bg="gray.200"
              p={6}
              m={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)' }}
              onClick={() => setShowDirectExpenses(true)}
            >
              <Icon as={GiMoneyStack} boxSize={24} />
              <Text fontSize="xl" fontWeight="bold" mt={2}>
                Direct Expenses
              </Text>
            </Box>
            <Box
              bg="gray.200"
              p={6}
              m={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)' }}
              onClick={() => setShowIndirectExpenses(true)}
              >
              <Icon as={GiMoneyStack} boxSize={24} />
              <Text fontSize="xl" fontWeight="bold" mt={2}>
              Indirect Expenses
              </Text>
              </Box>
              </Flex>
              </>
              )}
              {showDirectExpenses && (
                <>
                 <Center>
                 <Heading as="h2" size="lg" mb="4">
                 Direact Expenses Information
                 </Heading>
               </Center>
              <>
              <Flex flexDirection="column" alignItems="center">
              <Flex justifyContent="space-between" width="100%" mb={4}>
              <Button
              variant="solid"
              colorScheme="teal"
              leftIcon={<Icon as={FiArrowLeft} />}
              onClick={() => setShowDirectExpenses(false)}
              alignSelf="flex-start"
              >
              Back
              </Button>
           
              <Button
                ml={4}
                mr={4}
                variant="outline"
                colorScheme="teal"
              leftIcon={<Icon as={FiEdit} />}
              onClick={handleExportDirectExpensesToExcel}
              >
              Export to Excel
              </Button>
              <Button
    variant="outline"
    colorScheme="teal"
        leftIcon={<Icon as={FiEdit} />}
        onClick={handleExportDirectExpensesToJSON}
      >
        Export to JSON
      </Button>

      <Button
      ml={4}
      mr={4}
  variant="outline"
  colorScheme="teal"
  leftIcon={<Icon as={FiPaperclip} />}
  onClick={exportExpensesToPDF}
>
  Export to PDF
</Button>

      <Button
      
        mr={4}
    variant="outline"
    colorScheme="teal"
    leftIcon={<Icon as={FiFilter} />}
    onClick={handleFilter}
>
    Filter
</Button>
      <Button
            variant="solid"
            colorScheme="teal"
              leftIcon={<Icon as={FiPlus} />}
              onClick={handleAddDirectExpense}
              disabled={!formData.date || !formData.name || !formData.description || !formData.price || !formData.paymentMethod}
              >
              Add Expense
              </Button>
              </Flex>
              <Table variant="striped" colorScheme="teal" maxWidth="800px" mt={8}>
              <Thead>
              <Tr>
              <Th>Date</Th>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Price</Th>
              <Th>Payment Method</Th>
              <Th>Reference Number</Th>
              <Th>Actions</Th>
              </Tr>
              </Thead>
              <Tbody>
              {directExpensesData.slice().reverse().map((expense,index) => (
              <Tr key={expense._id}>
              <Td>{new Date(expense.date).toLocaleDateString()}</Td> 
              <Td>{expense.name}</Td>
              <Td>{expense.description}</Td>
              <Td>{expense.price}</Td>
              <Td>{expense.paymentMethod}</Td>
              <Td>{expense.referenceNumber}</Td>
              <Td>
                <Flex>
              <Button
           leftIcon={<Icon as={FiEdit} />}
           colorScheme="blue"
           variant="solid" mr={2}
              onClick={() => handleEdit(expense._id)}
              >
              Edit
              </Button>
              <Button
              leftIcon={<Icon as={FiTrash2} />}
              colorScheme="red"
              variant="solid"
              onClick={() => handleDelete(expense._id)}
              >
              Delete
              </Button>
              </Flex>
              </Td>
              </Tr>
              ))}
              </Tbody>
              </Table>
              </Flex>


<Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleFilterSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() => setIsFilterModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
                <Modal isOpen={showDirectFormModal} onClose={() => { 
                  setShowDirectFormModal(false); 
                  // Call your function here
                  clearFormData();
              }}>
              <ModalOverlay />
              <ModalContent>
              <ModalHeader>{selectedExpenseId ? "Edit Direct Expense" : "Add Direct Expense"}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <FormControl mt={4}>
              <FormLabel>Date</FormLabel>
              <Input type="date" name="date" value={formData.date} onChange={handleChange} />
              </FormControl>
              <FormControl mt={4}>
              <FormLabel>Name</FormLabel>
              <Input type="text" name="name" value={formData.name} onChange={handleChange} />
              </FormControl>
              <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Input type="text" name="description" value={formData.description} onChange={handleChange} />
              </FormControl>
              <FormControl mt={4}>
              <FormLabel>Price</FormLabel>
              <Input type="number" name="price" value={formData.price} onChange={handleChange} />
              </FormControl>
       
           <FormControl mt={4}>
            <FormLabel>Payment Method</FormLabel>
            <RadioGroup defaultValue={formData.paymentMethod} onChange={val => setFormData({ ...formData, paymentMethod: val })}>
              <Flex direction="row">
                <Radio  mr={4} value="Bank">Bank</Radio>
                <Radio value="Cash">Cash</Radio>
              </Flex>
            </RadioGroup>
          </FormControl>
          {formData.paymentMethod === 'Bank' && (
            <FormControl mt={4}>
              <FormLabel>Reference Number</FormLabel>
              <Input type="text" name="referenceNumber" value={formData.referenceNumber} onChange={handleChange} />
            </FormControl>
          )}
              </ModalBody>
              <ModalFooter>
              <Button colorScheme="teal" onClick={handleSubmitDirectExpense}>Submit</Button>
              </ModalFooter>
              </ModalContent>
              </Modal>
              </>
              </>
              )}
              {showIndirectExpenses && (
              <>
               <Center>
      <Heading as="h2" size="lg" mb="4">
      Indirect Expenses Information 
      </Heading>
    </Center>
              <Flex flexDirection="column" alignItems="center">
              <Flex justifyContent="space-between" width="100%" mb={4}>
              <Button
              variant="solid"
              colorScheme="teal"
              leftIcon={<Icon as={FiArrowLeft} />}
              onClick={() => setShowIndirectExpenses(false)}
              alignSelf="flex-start"
              >
              Back
              </Button>
              <Button
              variant="outline"
              colorScheme="teal"
              leftIcon={<Icon as={FiEdit} />}
              onClick={handleExportIndirectExpensesToExcel}
              >
              Export to Excel
              </Button>
              <Button
        variant="outline"
        colorScheme="teal"
        leftIcon={<Icon as={FiEdit} />}
        onClick={handleExportIndirectExpensesToJSON}
      >
        Export to JSON
      </Button>
      <Button
  variant="outline"
  colorScheme="teal"
  leftIcon={<Icon as={FiPaperclip} />}
  onClick={exportExpensesToPDF}
>
  Export to PDF
</Button>
      <Button
    variant="outline"
    colorScheme="teal"
    leftIcon={<Icon as={FiFilter} />}
    onClick={handleFilter}
>
    Filter
</Button>
      <Button
              variant="solid"
              colorScheme="teal"
              leftIcon={<Icon as={FiPlus} />}
              onClick={handleAddIndirectExpense}
              disabled={!formData.date || !formData.name || !formData.description || !formData.price || !formData.paymentMethod}
              >
              Add Expense
              </Button>
              </Flex>
              <Table variant="striped" colorScheme="teal" maxWidth="800px" mt={8}>
              <Thead>
              <Tr>
              <Th>Date</Th>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Price</Th>
              <Th>Payment Method</Th>
              <Th>Reference Number</Th>
              <Th>Actions</Th>
              </Tr>
              </Thead>
              <Tbody>
              {indirectExpensesData.slice().reverse().map((expense,index) => (
              <Tr key={expense._id}>
              <Td>{new Date(expense.date).toLocaleDateString()}</Td> 
              <Td>{expense.name}</Td>
              <Td>{expense.description}</Td>
              <Td>{expense.price}</Td>
              <Td>{expense.paymentMethod}</Td> 
              <Td>{expense.referenceNumber}</Td>
              <Td>
              <Flex>
              <Button
               leftIcon={<Icon as={FiEdit} />}
               colorScheme="blue"
               variant="solid" mr={2}
              onClick={() => handleEdit(expense._id)}
              >
              Edit
              </Button>
              <Button
              leftIcon={<Icon as={FiTrash2} />}
              colorScheme="red"
              variant="solid"
              onClick={() => handleDelete(expense._id)}
              >
              Delete
              </Button>
              </Flex>
              </Td>
              </Tr>
              ))}
              </Tbody>
              </Table>
              </Flex>

              <Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleFilterSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() => setIsFilterModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
              <Modal isOpen={showIndirectFormModal} onClose={() => {setShowIndirectFormModal(false);   clearFormData();}
            }>

           
              <ModalOverlay />
              <ModalContent>
              <ModalHeader>{selectedExpenseId ? "Edit Indirect Expense" : "Add Indirect Expense"}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <FormControl mt={4}>
              <FormLabel>Date</FormLabel>
              <Input type="date" name="date" value={formData.date} onChange={handleChange} />
              </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Name</FormLabel>
                  <Input type="text" name="name" value={formData.name} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Description</FormLabel>
                  <Input type="text" name="description" value={formData.description} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Price</FormLabel>
                  <Input type="number" name="price" value={formData.price} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
            <FormLabel>Payment Method</FormLabel>
            <RadioGroup defaultValue={formData.paymentMethod} onChange={val => setFormData({ ...formData, paymentMethod: val })}>
              <Flex direction="row">
                <Radio mr={4} value="Bank">Bank</Radio>
                <Radio value="Cash">Cash</Radio>
              </Flex>
            </RadioGroup>
          </FormControl>
          {formData.paymentMethod === 'Bank' && (
            <FormControl mt={4}>
              <FormLabel>Reference Number</FormLabel>
              <Input type="text" name="referenceNumber" value={formData.referenceNumber} onChange={handleChange} />
            </FormControl>
          )}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="teal" onClick={handleSubmitIndirectExpense}>Submit</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
      </div>
  );

};

export default Expenses;
