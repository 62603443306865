import React, { useState, useEffect } from 'react';
import { useToast, Flex, Text, Icon, Box, Table,Center,Heading, Thead, Tbody, Tr,Spinner, Th, Td, Button, Input, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { FiArrowLeft, FiPlus, FiEdit, FiTrash2, FiFilter, FiPaperclip } from 'react-icons/fi';
import { HiOutlineBanknotes } from "react-icons/hi2";
import { PiBankDuotone } from "react-icons/pi";
import axios from 'axios';
import * as XLSX from 'xlsx'; // Import XLSX library
import { baseURL } from '../baseURL.js';
import jsPDF from 'jspdf';


const Accounting = () => {
  const [showBankEntry, setShowBankEntry] = useState(false);
  const [showCashEntry, setShowCashEntry] = useState(false);
  const [bankEntries, setBankEntries] = useState([]);
  const [cashEntries, setCashEntries] = useState([]);
  const [formData, setFormData] = useState({ date: '', description: '', amount: '', transactionType: 'Credit', refNumber: '' });
  const [showBankFormModal, setShowBankFormModal] = useState(false);
  const [showCashFormModal, setShowCashFormModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [entriesWithBalance, setEntriesWithBalance] = useState([]);
  const [bankentriesWithBalance, setBankEntriesWithBalance] = useState([]);


  const toast = useToast();


  const [loading, setLoading] = useState(true); // Add loading state


  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    let runningBalance = 0;
    const calculatedEntries = cashEntries.slice().map((entry) => {
      if (entry.transactionType === 'Credit') {
        runningBalance += entry.amount;
      } else if (entry.transactionType === 'Debit') {
        runningBalance -= entry.amount;
      }
      return {
        ...entry,
        runningBalance: runningBalance.toFixed(2),
      };
    });
    setEntriesWithBalance(calculatedEntries);
  }, [cashEntries]);

  useEffect(() => {
    let runningBalance = 0;
    const bankcalculatedEntries = bankEntries.slice().map((entry) => {
      if (entry.transactionType === 'Credit') {
        runningBalance += entry.amount;
      } else if (entry.transactionType === 'Debit') {
        runningBalance -= entry.amount;
      }
      return {
        ...entry,
        runningBalance: runningBalance.toFixed(2),
      };
    });
    setBankEntriesWithBalance(bankcalculatedEntries);
  }, [bankEntries]);


  useEffect(() => {

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    // Set start date to one month ago and end date to current date
    setStartDate(oneMonthAgo.toISOString().slice(0, 10));
    setEndDate(new Date().toISOString().slice(0, 10));


    fetchBankEntries();
    fetchCashEntries();
  }, []); // Fetch data when component mounts



  const handleFilter = () => {
    setIsFilterModalOpen(true);
  };
  

  const handleFilterSubmit = () => {
    // Log start and end dates when filter is submitted

    fetchFilterBankEntries()
    fetchFilterCashEntries()
    setIsFilterModalOpen(false);
  };





  const fetchBankEntries = async () => {
    try {
      const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  
      const response = await axios.get(`${baseURL}/api/bank-entries`, {
        params: {
            email: storedEmail, // Sending email as a query parameter
            startDate: oneMonthAgo.toISOString(), // Sending start date as a query parameter
            endDate: new Date().toISOString() // Sending end date as a query parameter
        }
    });
  
      setBankEntries(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching bank entries:', error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
    }
  };


  const fetchFilterBankEntries = async () => {
    try {
      const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage
  
      const response = await axios.get(`${baseURL}/api/bank-entries`, {
        params: {
          email: storedEmail,
          startDate: startDate, // Sending start date as a query parameter
          endDate: endDate // Sending end date as a query parameter
        }
      });
  
      setBankEntries(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching bank entries:', error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
    }
  };
  
  const fetchCashEntries = async () => {
    try {
      const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  
      const response = await axios.get(`${baseURL}/api/cash-entries`, {
        params: {
          email: storedEmail,
          startDate: oneMonthAgo.toISOString(), // Sending start date as a query parameter
          endDate: new Date().toISOString() // Sending end date as a query parameter
        }
      });
  
      setCashEntries(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching cash entries:', error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
    }
  };


  const fetchFilterCashEntries = async () => {
    try {
      const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage
  
      const response = await axios.get(`${baseURL}/api/cash-entries`, {
        params: {
            email: storedEmail, // Sending email as a query parameter


            startDate: startDate, // Sending start date as a query parameter
            endDate: endDate // Sending end date as a query parameter
      
        }
    });
    
  
      setCashEntries(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching cash entries:', error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
    }
  };
  
  
  const exportAccountingToPDF = (entries, entryType) => {
    let headers, data;
    let runningBalance = 0;
    
    if (entryType === 'Bank') {
      headers = [['Date', 'Description', 'Credit', 'Debit', 'Balance', 'Reference Number']];
      data = entries.map((entry) => {
        if (entry.transactionType === 'Credit') {
          runningBalance += parseFloat(entry.amount) || 0;
        } else if (entry.transactionType === 'Debit') {
          runningBalance -= parseFloat(entry.amount) || 0;
        }
  
        return [
          new Date(entry.date).toLocaleDateString(),
          entry.description || '',
          entry.transactionType === 'Credit' ? entry.amount : '',
          entry.transactionType === 'Debit' ? entry.amount : '',
          runningBalance.toFixed(2),
          entry.refNumber || '',
        ];
      });
    } else if (entryType === 'Cash') {
      headers = [['Date', 'Description', 'Credit', 'Debit', 'Balance']];
      data = entries.map((entry) => {
        if (entry.transactionType === 'Credit') {
          runningBalance += parseFloat(entry.amount) || 0;
        } else if (entry.transactionType === 'Debit') {
          runningBalance -= parseFloat(entry.amount) || 0;
        }
  
        return [
          new Date(entry.date).toLocaleDateString(),
          entry.description || '',
          entry.transactionType === 'Credit' ? entry.amount : '',
          entry.transactionType === 'Debit' ? entry.amount : '',
          runningBalance.toFixed(2),
        ];
      });
    } else {
      return; // Exit if entryType is neither 'Bank' nor 'Cash'
    }
  
    // Calculate the total credits and debits
    const totalCredits = entries
      .filter(entry => entry.transactionType === 'Credit')
      .reduce((total, entry) => total + parseFloat(entry.amount) || 0, 0);
    
    const totalDebits = entries
      .filter(entry => entry.transactionType === 'Debit')
      .reduce((total, entry) => total + parseFloat(entry.amount) || 0, 0);
  
    const pdf = new jsPDF();
  
    // Add appropriate title based on entry type
    pdf.setFontSize(18);
    pdf.text(`${entryType} Entry Information from ${startDate} till ${endDate}`, 14, 20);
  
    // Generate autoTable
    pdf.autoTable({
      head: headers,
      body: data,
      startY: 30, // Adjust the starting Y position as needed
      theme: 'grid',
      headStyles: {
        fillColor: [211, 211, 211], // Set heading background color to light gray (RGB: 211, 211, 211)
        textColor: [0, 0, 0] // Set heading text color to black
      },
      styles: {
        lineColor: [0, 0, 0], // Set line color to black
        textColor: [0, 0, 0], // Set text color to black
        fontStyle: 'normal', // Set font style to normal
        lineWidth: 0.1, // Set line width
      },
    });
  
    // Display the total credits and debits at the end
    pdf.setFontSize(12);
    pdf.text(`Total Credits: ${totalCredits.toFixed(2)}`, 14, pdf.lastAutoTable.finalY + 10);
    pdf.text(`Total Debits: ${totalDebits.toFixed(2)}`, 14, pdf.lastAutoTable.finalY + 20);
    pdf.text(`Balance: ${(totalCredits - totalDebits).toFixed(2)}`, 14, pdf.lastAutoTable.finalY + 30);
  
    // Save the generated PDF file with a custom title
    pdf.save(`${entryType.toLowerCase()}_entry.pdf`);
  };
  
  


    // Add handleExportBankEntryToPDF function
    const handleExportBankEntryToPDF = () => {
      exportAccountingToPDF(bankEntries, 'Bank');
    };
  
    // Add handleExportCashEntryToPDF function
    const handleExportCashEntryToPDF = () => {
      exportAccountingToPDF(cashEntries, 'Cash');
    };
  
  const handleBankEntryClick = () => {
    setShowBankEntry(true);
    setShowCashEntry(false);
    resetFormData();
  };

  const handleCashEntryClick = () => {
    setShowBankEntry(false);
    setShowCashEntry(true);
    resetFormData();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTransactionTypeChange = (value) => {
    setFormData({ ...formData, transactionType: value });
  };

  const handleAddBankEntry = () => {
    setShowBankFormModal(true);
    resetFormData();
  };

  const handleAddCashEntry = () => {
    setShowCashFormModal(true);
    resetFormData();
  };
  const handleSubmitBankEntry = async () => {
    try {

            // Check if any of the form fields are empty
            if (!formData.date || !formData.description || !formData.amount || !formData.refNumber) {
              // If any field is empty, show an error toast
              toast({
                title: "Incomplete Submission",
                description: "Please ensure all fields are filled in.",
                  status: "info",
                  duration: 5000,
                  isClosable: true,
                  position: 'bottom-left'
              });
              return; // Exit the function if any field is empty
          }


        const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage

        // Add email to the formData object
        const formDataWithEmail = { ...formData, email: storedEmail };

        if (editIndex !== null) {
            await axios.put(`${baseURL}/api/bank-entries/${bankEntries[editIndex]._id}`, formDataWithEmail);
            const updatedEntries = [...bankEntries];
            updatedEntries[editIndex] = { ...formDataWithEmail }; // Use the updated form data with email
            setBankEntries(updatedEntries);
            setEditIndex(null);
        } else {
            const response = await axios.post(`${baseURL}/api/bank-entries`, formDataWithEmail);
            setBankEntries([...bankEntries, response.data]);
        }
        resetFormData();
        setShowBankFormModal(false);
    } catch (error) {
        console.error('Error handling bank entry:', error);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {

          if (!toast.isActive("profileNotFound")) {
            toast({
              id: "profileNotFound",
              title: "Please fill details on profile screen.",
              description: "Your profile details were incomplete",
              status: "info",
              duration: 10000,
              isClosable: true,
              position: 'bottom-left'
            });
          }
        }
        
    }
};


const handleSubmitCashEntry = async () => {
  try {

     // Check if any of the form fields are empty
     if (!formData.date || !formData.description || !formData.amount) {
      // If any field is empty, show an error toast
      toast({
        title: "Incomplete Submission",
        description: "Please ensure all fields are filled in.",
          status: "info",
          duration: 5000,
          isClosable: true,
          position: 'bottom-left'
      });
      return; // Exit the function if any field is empty
  }


      const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage
      
      // Include email in the formData object
      const formDataWithEmail = { ...formData, email: storedEmail };

      const response = await axios.post(`${baseURL}/api/cash-entries`, formDataWithEmail);
      setCashEntries([...cashEntries, response.data]);
      resetFormData();
      setShowCashFormModal(false);
  } catch (error) {
      console.error('Error handling cash entry:', error);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
  }
};


const handleDeleteBankEntry = async (id) => {
  try {
    const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage

    await axios.delete(`${baseURL}/api/bank-entries/${id}`, {
      data: { email: storedEmail } // Include email in the request data
    });

    const updatedBankEntries = bankEntries.filter((entry) => entry._id !== id);
    setBankEntries(updatedBankEntries);
  } catch (error) {
    console.error('Error deleting bank entry:', error);
  }
};


const handleDeleteCashEntry = async (id) => {
  try {
    const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage

    await axios.delete(`${baseURL}/api/cash-entries/${id}`, {
      data: { email: storedEmail } // Include email in the request data
    });

    const updatedCashEntries = cashEntries.filter((entry) => entry._id !== id);
    setCashEntries(updatedCashEntries);
  } catch (error) {
    console.error('Error deleting cash entry:', error);
  }
};


  const handleEditBankEntry = (index) => {
    const entry = bankEntries[index];
    setFormData({ ...entry });
    setEditIndex(index); // Set the index for editing
    setShowBankFormModal(true);
  };

  const handleEditCashEntry = (index) => {
    const entry = cashEntries[index];
    setFormData({ ...entry });
    setEditIndex(index);
    setShowCashFormModal(true);
  };

  const resetFormData = () => {
    setFormData({ date: '', description: '', amount: '', transactionType: 'Credit', refNumber: '' });
  };


  const handleExportBankEntryToExcel = () => {
    // Prepare data for export and change date format
    const formattedData = bankEntries.map(({ _id, __v, email, date, ...BankEntry }) => {
      const formattedDate = new Date(date).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      return { ...BankEntry, date: formattedDate };
    });
  
    // Get the first entry date in bankEntries
    const firstEntryDate = bankEntries.length > 0 ? new Date(bankEntries[0].date).toLocaleDateString('en-GB') : 'unknown_date';
  
    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(formattedData);
  
    // Create workbook and add worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bank Entry");
  
    // Save workbook as Excel file with the first entry date in the file name
    XLSX.writeFile(wb, `Bank_Entry_${firstEntryDate}.xlsx`);
  };
  const handleExportCashEntryToExcel = () => {
    // Prepare data for export and change date format
    const formattedData = cashEntries
      .filter(entry => Object.values(entry).some(value => value !== '' && value !== null)) // Filter out entries with empty values
      .map(({ _id, __v, email, date, ...CashEntry }) => {
        const formattedDate = new Date(date).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        return { ...CashEntry, date: formattedDate };
      });
  
    // Get the first entry date in cashEntries
    const firstEntryDate = cashEntries.length > 0 ? new Date(cashEntries[0].date).toLocaleDateString('en-GB') : 'unknown_date';
  
    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(formattedData);
  
    // Create workbook and add worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Cash Entry");
  
    // Save workbook as Excel file with the first entry date in the file name
    XLSX.writeFile(wb, `Cash_Entry_${firstEntryDate}.xlsx`);
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  const handleExportBankEntryToJSON = () => {
    // Map and format the date in the desired format
    const formattedData = bankEntries.map(({ _id, email, __v, date, ...entry }) => {
      const formattedDate = formatDate(date);
      return { ...entry, date: formattedDate };
    });
  
    // Get the first entry date from the first entry in bankEntries
    const firstEntryDate = bankEntries.length > 0 ? formatDate(bankEntries[0].date) : 'unknown_date';
  
    // Convert the data to JSON
    const jsonData = JSON.stringify(formattedData, null, 2);
  
    // Create a Blob object and initiate download
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // Include formatted date in the file name
    a.download = `${firstEntryDate}_Bank_Entry_data.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  
  
  const handleExportCashEntryToJSON = () => {
    // Map and format the date in the desired format
    const formattedData = cashEntries.map(({ _id, email, __v, date, ...entry }) => {
      const formattedDate = formatDate(date);
      return { ...entry, date: formattedDate };
    });
  
    // Get the first entry date from the first entry in cashEntries
    const firstEntryDate = cashEntries.length > 0 ? formatDate(cashEntries[0].date) : 'unknown_date';
  
    // Convert the data to JSON
    const jsonData = JSON.stringify(formattedData, null, 2);
  
    // Create a Blob object and initiate download
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // Include formatted date in the file name
    a.download = `${firstEntryDate}_Cash_Entry_data.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  
  let runningBalance = 0;

  const totalCredits = cashEntries
    .filter(entry => entry.transactionType === 'Credit')
    .reduce((total, entry) => total + entry.amount, 0);

  const totalDebits = cashEntries
    .filter(entry => entry.transactionType === 'Debit')
    .reduce((total, entry) => total + entry.amount, 0);

  return (
    <>
          <div style={{ position: 'relative', minHeight: '100vh' }}>
    {loading && (
      <div style={{ backdropFilter: 'blur(5px)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
        <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="teal" size="xl" />
      </div>
    )}
      {!showBankEntry && !showCashEntry && (
        <>
          <Text fontSize="3xl" fontWeight="bold" mb={4}>
            Accounting Page
          </Text>
          <Flex justifyContent="center">
            <Box
              bg="gray.200"
              p={6}
              m={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)' }}
              onClick={handleBankEntryClick}
            >
              <Icon as={PiBankDuotone} boxSize={24} />
              <Text fontSize="xl" fontWeight="bold" mt={2}>
                Bank Entry
              </Text>
            </Box>
            <Box
              bg="gray.200"
              p={6}
              m={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)' }}
              onClick={handleCashEntryClick}
            >
              <Icon as={HiOutlineBanknotes} boxSize={24} />
              <Text fontSize="xl" fontWeight="bold" mt={2}>
                Cash Entry
              </Text>
            </Box>
          </Flex>
        </>
      )}
      {showBankEntry && (
        <>
         <Center>
      <Heading as="h2" size="lg" mb="4">
      Bank Entry Information
      </Heading>
    </Center>
          <Flex flexDirection="column" alignItems="center">
            <Flex justifyContent="space-between" width="100%" mb={4}>
              <Button
                variant="solid"
                colorScheme="teal"
                leftIcon={<Icon as={FiArrowLeft} />}
                onClick={() => setShowBankEntry(false)}
                alignSelf="flex-start"
              >
                Back
              </Button>
          
              <Button
              variant="outline"
              colorScheme="teal"
              ml={4}
        
              leftIcon={<Icon as={FiEdit} />}
              onClick={handleExportBankEntryToExcel}
              >
              Export to Excel
              </Button>
              <Button
                         ml={4}
  variant="outline"
  colorScheme="teal"
  leftIcon={<Icon as={FiEdit} />}
  onClick={handleExportBankEntryToJSON}
>
  Export to JSON
</Button>
<Button
              ml={4}
              variant="outline"
              colorScheme="teal"
              leftIcon={<Icon as={FiPaperclip} />}
              onClick={handleExportBankEntryToPDF}
            >
              Export to PDF
            </Button>
<Button
    variant="outline"
    colorScheme="teal"
    ml={4}
    leftIcon={<Icon as={FiFilter} />}
    onClick={handleFilter}
>
    Filter
</Button>
<Button
           ml={4}
                variant="solid"
                colorScheme="teal"
                leftIcon={<Icon as={FiPlus} />}
                onClick={handleAddBankEntry}
                disabled={!formData.date || !formData.description || !formData.amount}
              >
                Add Bank Entry
              </Button>
            </Flex>
            <Table variant="striped" colorScheme="teal" maxWidth="800px" mt={8}>
              <Thead>
                <Tr>
                  <Th>DATE</Th>
                  <Th>DESCRIPTION</Th>
                  <Th>CREDIT</Th>
                  <Th>DEBIT</Th>
                  <Th>BALANCE</Th>
                  <Th>REFERENCE NUMBER</Th>
                  <Th>ACTIONS</Th>
                </Tr>
              </Thead>
              <Tbody>
              {bankentriesWithBalance.slice().reverse().map((entry, index) => (
        <Tr key={index}>
          <Td>{new Date(entry.date).toLocaleDateString()}</Td>
          <Td>{entry.description}</Td>
          {entry.transactionType === 'Credit' ? (
            <>
              <Td>{entry.amount.toFixed(2)}</Td>
              <Td></Td>
            </>
          ) : (
            <>
              <Td></Td>
              <Td>{entry.amount.toFixed(2)}</Td>
            </>
          )}
          <Td>{entry.runningBalance}</Td>
                    <Td>{entry.refNumber}</Td>
                    <Td>
                    <Flex>
                      <Button
                        leftIcon={<Icon as={FiEdit} />}
                        colorScheme="blue"
                        variant="solid"
                          mr={2}
                        onClick={() => handleEditBankEntry(index)}
                      >
                        Edit
                      </Button>
                      <Button
                    leftIcon={<Icon as={FiTrash2} />}
                    colorScheme="red"
                    variant="solid"
                        onClick={() => handleDeleteBankEntry(entry._id)}
                      >
                        Delete
                      </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>

<Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleFilterSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() => setIsFilterModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
          <Modal isOpen={showBankFormModal} onClose={() => setShowBankFormModal(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{editIndex !== null ? 'Edit Bank Entry' : 'Add Bank Entry'}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl mt={4}>
                  <FormLabel>Date</FormLabel>
                  <Input type="date" name="date" value={formData.date} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Description</FormLabel>
                  <Input type="text" name="description" value={formData.description} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Amount</FormLabel>
                  <Input type="number" name="amount" value={formData.amount} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Transaction Type</FormLabel>
                  <RadioGroup name="transactionType" value={formData.transactionType} onChange={(value) => handleTransactionTypeChange(value)}>
                    <Stack direction="row">
                      <Radio value="Credit">Credit</Radio>
                      <Radio value="Debit">Debit</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Reference Number</FormLabel>
                  <Input ttype="number" name="refNumber" value={formData.refNumber} onChange={handleChange} />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="teal" onClick={handleSubmitBankEntry}>{editIndex !== null ? 'Update' : 'Submit'}</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
      {showCashEntry && (
        <>
         <Center>
      <Heading as="h2" size="lg" mb="4">
      Cash Entry Information
      </Heading>
    </Center>
          <Flex flexDirection="column" alignItems="center">
            <Flex justifyContent="space-between" width="100%" mb={4}>
              <Button
                variant="solid"
                colorScheme="teal"
                mr="2"
                leftIcon={<Icon as={FiArrowLeft} />}
                onClick={() => setShowCashEntry(false)}
                alignSelf="flex-start"
              >
                Back
              </Button>
        
              <Button
              variant="outline"
              colorScheme="teal"
              ml={4}
              leftIcon={<Icon as={FiEdit} />}
              onClick={handleExportCashEntryToExcel}
              >
              Export to Excel
              </Button>
              <Button
  variant="outline"
  colorScheme="teal"
  ml={4}
  leftIcon={<Icon as={FiEdit} />}
  onClick={handleExportCashEntryToJSON}
>
  Export to JSON
</Button>
<Button
                ml={4}
              variant="outline"
              colorScheme="teal"
              leftIcon={<Icon as={FiPaperclip} />}
              onClick={handleExportCashEntryToPDF}
            >
              Export to PDF
            </Button>
<Button
    variant="outline"
    colorScheme="teal"
    ml={4}
    leftIcon={<Icon as={FiFilter} />}
    onClick={handleFilter}
>
    Filter
</Button>
<Button
                variant="solid"
                colorScheme="teal"
                ml={4}
                leftIcon={<Icon as={FiPlus} />}
                onClick={handleAddCashEntry}
                disabled={!formData.date || !formData.description || !formData.amount}
              >
                Add Cash Entry
              </Button>
            </Flex>
            <Table variant="striped" colorScheme="teal" maxWidth="800px" mt={8}>
      <Thead>
        <Tr>
          <Th>DATE</Th>
          <Th>DESCRIPTION</Th>
          <Th>CREDIT</Th>
          <Th>DEBIT</Th>
          <Th>BALANCE</Th>
          <Th>ACTIONS</Th>
        </Tr>
      </Thead>
      <Tbody>
      {entriesWithBalance.slice().reverse().map((entry, index) => (
        <Tr key={index}>
          <Td>{new Date(entry.date).toLocaleDateString()}</Td>
          <Td>{entry.description}</Td>
          {entry.transactionType === 'Credit' ? (
            <>
              <Td>{entry.amount.toFixed(2)}</Td>
              <Td></Td>
            </>
          ) : (
            <>
              <Td></Td>
              <Td>{entry.amount.toFixed(2)}</Td>
            </>
          )}
          <Td>{entry.runningBalance}</Td>
          <Td>
            <Flex>
              <Button
                leftIcon={<Icon as={FiEdit} />}
                colorScheme="blue"
                variant="solid"
                mr={2}
                onClick={() => handleEditCashEntry(index)}
              >
                Edit
              </Button>
              <Button
                leftIcon={<Icon as={FiTrash2} />}
                colorScheme="red"
                variant="solid"
                onClick={() => handleDeleteCashEntry(entry._id)}
              >
                Delete
              </Button>
            </Flex>
          </Td>
        </Tr>
      ))}
    </Tbody>
    </Table>
          </Flex>

<Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleFilterSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() => setIsFilterModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
          <Modal isOpen={showCashFormModal} onClose={() => setShowCashFormModal(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Cash Entry</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl mt={4}>
                  <FormLabel>Date</FormLabel>
                  <Input type="date" name="date" value={formData.date} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Description</FormLabel>
                  <Input type="text" name="description" value={formData.description} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Amount</FormLabel>
                  <Input type="number" name="amount" value={formData.amount} onChange={handleChange} />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Transaction Type</FormLabel>
                  <RadioGroup name="transactionType" value={formData.transactionType} onChange={(value) => handleTransactionTypeChange(value)}>
                    <Stack direction="row">
                      <Radio value="Credit">Credit</Radio>
                      <Radio value="Debit">Debit</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="teal" onClick={handleSubmitCashEntry}>Submit</Button>
              </ModalFooter> 
            </ModalContent>
          </Modal>
        </>
      )}
      </div>
    </>
  );
};

export default Accounting;

