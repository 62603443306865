import React, { useState, useEffect } from 'react';
import { useToast, Flex, Text, Icon, Box, Table, Thead,Spinner,Center,Heading, Tbody,Radio,RadioGroup, Tr, Th, Td, Button, Input, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { FiArrowLeft, FiPlus, FiEdit, FiTrash2,FiFilter, FiPaperclip } from 'react-icons/fi';
import { GiTakeMyMoney } from "react-icons/gi";
import * as XLSX from 'xlsx'; // Import XLSX library
import { baseURL } from '../baseURL.js';
import axios from 'axios';

import jsPDF from 'jspdf';

const Income = () => {
  const [showDirectIncome, setShowDirectIncome] = useState(false);
  const [showIndirectIncome, setShowIndirectIncome] = useState(false);
  const [directIncomeData, setDirectIncomeData] = useState([]);
  const [indirectIncomeData, setIndirectIncomeData] = useState([]);
  const [formData, setFormData] = useState({ date: '', name: '', description: '', price: '', paymentMethod: '',  referenceNumber: '',});
  const [showDirectFormModal, setShowDirectFormModal] = useState(false);
  const [showIndirectFormModal, setShowIndirectFormModal] = useState(false);
  const [selectedIncomeId, setSelectedIncomeId] = useState(null);
  const toast = useToast();


  const [loading, setLoading] = useState(true); // Add loading state


  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    // Set start date to one month ago and end date to current date
    setStartDate(oneMonthAgo.toISOString().slice(0, 10));
    setEndDate(new Date().toISOString().slice(0, 10));

    fetchDirectIncome();
    fetchIndirectIncome();
  }, []);



  const handleFilter = () => {
    setIsFilterModalOpen(true);
  };
  

  const handleFilterSubmit = () => {
    // Log start and end dates when filter is submitted
 


    fetchFilterDirectIncome()
    fetchFilterIndirectIncome()
    setIsFilterModalOpen(false);
  };



  const clearFormData = () => {
    setFormData({
        date: '',
        name: '',
        description: '',
        price: '',
        paymentMethod: '',
        referenceNumber: '',
    });
};

  const fetchDirectIncome = async () => {
    try {
      
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const storedEmail = localStorage.getItem('email');
              // Fetch direct income data from the server within the specified date range
              const response = await axios.get(`${baseURL}/api/direct-income`, {
                params: {
                    email: storedEmail, // Sending email as a query parameter
                    startDate: oneMonthAgo.toISOString(), // Sending start date as a query parameter
                    endDate: new Date().toISOString() // Sending end date as a query parameter
                }
            });
        setDirectIncomeData(response.data);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching direct income:', error);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {
            if (!toast.isActive("profileNotFound")) {
                toast({
                    id: "profileNotFound",
                    title: "Please fill details on profile screen.",
                    description: "Your profile details were incomplete",
                    status: "info",
                    duration: 10000,
                    isClosable: true,
                    position: 'bottom-left'
                });
            }
        }
    }
};


const fetchFilterDirectIncome = async () => {
  try {
      const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage
      const response = await axios.get(`${baseURL}/api/direct-income`, {
        params: {
            email: storedEmail, // Sending email as a query parameter
            startDate: startDate, // Sending start date as a query parameter
              endDate: endDate // Sending end date as a query parameter
          }
    });
      setDirectIncomeData(response.data);
      setLoading(false);
  } catch (error) {
      console.error('Error fetching direct income:', error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {
          if (!toast.isActive("profileNotFound")) {
              toast({
                  id: "profileNotFound",
                  title: "Please fill details on profile screen.",
                  description: "Your profile details were incomplete",
                  status: "info",
                  duration: 10000,
                  isClosable: true,
                  position: 'bottom-left'
              });
          }
      }
  }
};


const fetchIndirectIncome = async () => {
    try {
      
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const storedEmail = localStorage.getItem('email');
    const response = await axios.get(`${baseURL}/api/indirect-income`, {
      params: {
          email: storedEmail, // Sending email as a query parameter
          startDate: oneMonthAgo.toISOString(), // Sending start date as a query parameter
          endDate: new Date().toISOString() // Sending end date as a query parameter
      }
  });
        setIndirectIncomeData(response.data);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching indirect income:', error);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {
            if (!toast.isActive("profileNotFound")) {
                toast({
                    id: "profileNotFound",
                    title: "Please fill details on profile screen.",
                    description: "Your profile details were incomplete",
                    status: "info",
                    duration: 10000,
                    isClosable: true,
                    position: 'bottom-left'
                });
            }
        }
    }
};


const fetchFilterIndirectIncome = async () => {
  try {
      const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage
      const response = await axios.get(`${baseURL}/api/indirect-income`, {
        params: {
            email: storedEmail, // Sending email as a query parameter
            startDate: startDate, // Sending start date as a query parameter
            endDate: endDate // Sending end date as a query parameter
         }
    });
      setIndirectIncomeData(response.data);
      setLoading(false);
  } catch (error) {
      console.error('Error fetching indirect income:', error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {
          if (!toast.isActive("profileNotFound")) {
              toast({
                  id: "profileNotFound",
                  title: "Please fill details on profile screen.",
                  description: "Your profile details were incomplete",
                  status: "info",
                  duration: 10000,
                  isClosable: true,
                  position: 'bottom-left'
              });
          }
      }
  }
};


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddDirectIncome = () => {
    setShowDirectFormModal(true);
  };

  const handleAddIndirectIncome = () => {
    setShowIndirectFormModal(true);
  };

  const handleSubmitDirectIncome = async () => {
    try {
        const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage

        if (selectedIncomeId) {
            const response = await axios.put(`${baseURL}/api/direct-income/${selectedIncomeId}`, { ...formData, email: storedEmail }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
          
            setSelectedIncomeId(null);
        } else {
            const response = await axios.post(`${baseURL}/api/direct-income`, { ...formData, email: storedEmail }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
           
        }
        setFormData({ date: '', name: '', description: '', price: '', paymentMethod: '',  referenceNumber: '', });
        setShowDirectFormModal(false);
        fetchDirectIncome();
    } catch (error) {
        console.error('Error handling direct income submission:', error);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {
            if (!toast.isActive("profileNotFound")) {
                toast({
                    id: "profileNotFound",
                    title: "Please fill details on profile screen.",
                    description: "Your profile details were incomplete",
                    status: "info",
                    duration: 10000,
                    isClosable: true,
                    position: 'bottom-left'
                });
            }
        }
    }
};

const handleSubmitIndirectIncome = async () => {
    try {
        const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage

        if (selectedIncomeId) {
            const response = await axios.put(`${baseURL}/api/indirect-income/${selectedIncomeId}`, { ...formData, email: storedEmail }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
           
            setSelectedIncomeId(null);
        } else {
            const response = await axios.post(`${baseURL}/api/indirect-income`, { ...formData, email: storedEmail }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
           
        }
        setFormData({ date: '', name: '', description: '', price: '', paymentMethod: '',  referenceNumber: '', });
        setShowIndirectFormModal(false);
        fetchIndirectIncome();
    } catch (error) {
        console.error('Error handling indirect income submission:', error);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {
            if (!toast.isActive("profileNotFound")) {
                toast({
                    id: "profileNotFound",
                    title: "Please fill details on profile screen.",
                    description: "Your profile details were incomplete",
                    status: "info",
                    duration: 10000,
                    isClosable: true,
                    position: 'bottom-left'
                });
            }
        }
    }
};


// Inside handleEdit function
const handleEdit = (income, type) => {
  setFormData({ ...income });
  setSelectedIncomeId(income._id); // Assuming the ID field is named _id
  if (type === 'direct') {
    setShowDirectFormModal(true);
  } else {
    setShowIndirectFormModal(true);
  }
};
const handleDelete = async (id, type) => {
  try {
    const storedEmail = localStorage.getItem('email'); // Retrieve email from localStorage

    const response = await axios.delete(`${baseURL}/api/${type === 'direct' ? 'direct-income' : 'indirect-income'}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data: { email: storedEmail }, // Include email in the request body
    });

    if (response.status !== 200) {
      throw new Error(`Failed to delete ${type === 'direct' ? 'direct' : 'indirect'} income`);
    }

    // Remove the deleted item from the local state
    if (type === 'direct') {
      setDirectIncomeData(directIncomeData.filter(income => income._id !== id));
    } else {
      setIndirectIncomeData(indirectIncomeData.filter(income => income._id !== id));
    }
  } catch (error) {
    console.error(`Error deleting ${type === 'direct' ? 'direct' : 'indirect'} income:`, error);
  }
};
const handleExportDirectIncomeToJSON = () => {
  // Map and format the date in the desired format
  const formattedData = directIncomeData.map(({ _id, email, __v, date, ...income }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    // Remove keys with empty values
    Object.keys(income).forEach(key => (income[key] === '' || income[key] === null) && delete income[key]);
    return { ...income, date: formattedDate };
  });

  // Convert the data to JSON
  const jsonData = JSON.stringify(formattedData);

  // Get the date from the first entry in directIncomeData
  const firstEntryDate = directIncomeData.length > 0 ? new Date(directIncomeData[0].date).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }) : 'unknown_date';

  // Create a Blob object and initiate download
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  // Include firstEntryDate in the file name
  a.download = `direct_income_data_${firstEntryDate}.json`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const handleExportIndirectIncomeToJSON = () => {
  // Map and format the date in the desired format
  const formattedData = indirectIncomeData.map(({ _id, email, __v, date, ...income }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    // Remove keys with empty values
    Object.keys(income).forEach(key => (income[key] === '' || income[key] === null) && delete income[key]);
    return { ...income, date: formattedDate };
  });

  // Convert the data to JSON
  const jsonData = JSON.stringify(formattedData);

  // Get the date from the first entry in indirectIncomeData
  const firstEntryDate = indirectIncomeData.length > 0 ? new Date(indirectIncomeData[0].date).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }) : 'unknown_date';

  // Create a Blob object and initiate download
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  // Include firstEntryDate in the file name
  a.download = `indirect_income_data_${firstEntryDate}.json`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};



const downloadJSON = (data, filename) => {
  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};



const handleExportDirectIncomeToExcel = () => {
  // Prepare data for export and change date format
  const formattedData = directIncomeData.map(({ _id, __v, email, date, ...income }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return { ...income, date: formattedDate };
  });

  // Get the first entry date in directIncomeData
  const firstEntryDate = directIncomeData.length > 0 ? new Date(directIncomeData[0].date).toLocaleDateString('en-GB') : 'unknown_date';

  // Convert data to worksheet
  const ws = XLSX.utils.json_to_sheet(formattedData);

  // Create workbook and add worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Direct Income");

  // Save workbook as Excel file with the first entry date in the file name
  XLSX.writeFile(wb, `Direct_Income_${firstEntryDate}.xlsx`);
};

const handleExportIndirectIncomeToExcel = () => {
  // Prepare data for export and change date format
  const formattedData = indirectIncomeData.map(({ _id, __v, email, date, ...income }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return { ...income, date: formattedDate };
  });

  // Get the first entry date in indirectIncomeData
  const firstEntryDate = indirectIncomeData.length > 0 ? new Date(indirectIncomeData[0].date).toLocaleDateString('en-GB') : 'unknown_date';

  // Convert data to worksheet
  const ws = XLSX.utils.json_to_sheet(formattedData);

  // Create workbook and add worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Indirect Income");

  // Save workbook as Excel file with the first entry date in the file name
  XLSX.writeFile(wb, `Indirect_Income_${firstEntryDate}.xlsx`);
};

const exportIncomeToPDF = () => {
  const incomeData = showDirectIncome ? directIncomeData : indirectIncomeData;
  let totalSum = 0; // Variable to store the sum of income prices

  const data = incomeData.map((income) => {
    totalSum += income.price; // Add the price of each income item to the total sum

    return [
      new Date(income.date).toLocaleDateString(),
      income.referenceNumber, // Include the reference number
      income.name,
      income.description,
      income.price,
      income.paymentMethod // Include the payment method
    ];
  });

  const headers = [
    ['Date', 'Reference Number', 'Name', 'Description', 'Price', 'Payment Method']
  ];

  const pdf = new jsPDF();
  
  // Add Income Information heading
  pdf.setFontSize(18);
  pdf.text(`${showDirectIncome ? 'Direct' : 'Indirect'} Income Information from ${startDate} till ${endDate}`, 14, 20);

  // Generate autoTable
  pdf.autoTable({
    head: headers,
    body: data,
    startY: 30, // Adjust the starting Y position as needed
    theme: 'grid',
    headStyles: {
      fillColor: [211, 211, 211], // Set heading background color to light gray (RGB: 211, 211, 211)
      textColor: [0, 0, 0] // Set heading text color to black
    },
    styles: {
      lineColor: [0, 0, 0], // Set line color to black
      textColor: [0, 0, 0], // Set text color to black
      fontStyle: 'normal', // Set font style to normal
      lineWidth: 0.1 // Set line width
    }
  });

  // Display the total sum of income at the end
  pdf.setFontSize(12);
  pdf.text(`Total Sum: ${totalSum.toFixed(2)}`, 14, pdf.lastAutoTable.finalY + 10);

  pdf.save(`${showDirectIncome ? 'direct' : 'indirect'}_income.pdf`);
};



  return (
    <>
        <div style={{ position: 'relative', minHeight: '100vh' }}>
    {loading && (
      <div style={{ backdropFilter: 'blur(5px)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
        <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="teal" size="xl" />
      </div>
    )}
      {!showDirectIncome && !showIndirectIncome && (
        <>
          <Text fontSize="3xl" fontWeight="bold" mb={4}>
            Income
          </Text>
          <Flex justifyContent="center">
            <Box
              bg="gray.200"
              p={6}
              m={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)' }}
              onClick={() => setShowDirectIncome(true)}
            >
              <Icon as={GiTakeMyMoney} boxSize={24} />
              <Text fontSize="xl" fontWeight="bold" mt={2}>
                Direct Income
              </Text>
            </Box>
            <Box
              bg="gray.200"
              p={6}
              m={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)' }}
              onClick={() => setShowIndirectIncome(true)}
            >
        <Icon as={GiTakeMyMoney} boxSize={24} />
<Text fontSize="xl" fontWeight="bold" mt={2}>
  Indirect Income
</Text>
</Box>
</Flex>
</>
)}
{showDirectIncome && (
<>
<Center>
      <Heading as="h2" size="lg" mb="4">
      Direct Income Information
      </Heading>
    </Center>
<Flex flexDirection="column" alignItems="center">
<Flex justifyContent="space-between" width="100%" mb={4}>
<Button
variant="solid"
colorScheme="teal"
leftIcon={<Icon as={FiArrowLeft} />}
onClick={() => setShowDirectIncome(false)}
alignSelf="flex-start"
>
Back
</Button>

<Button
                   variant="outline"
                   colorScheme="teal"
                   ml="4"
              leftIcon={<Icon as={FiEdit} />}
              onClick={handleExportDirectIncomeToExcel}
              >
              Export to Excel
              </Button>
              <Button
                variant="outline"
                colorScheme="teal"
                ml="4"
            leftIcon={<Icon as={FiEdit} />}
            onClick={handleExportDirectIncomeToJSON}
          >
            Export to JSON
          </Button>
          <Button
      ml={4}

  variant="outline"
  colorScheme="teal"
  leftIcon={<Icon as={FiPaperclip} />}
  onClick={exportIncomeToPDF}
>
  Export to PDF
</Button>
          <Button
    variant="outline"
    colorScheme="teal"
    ml="4"
    leftIcon={<Icon as={FiFilter} />}
    onClick={handleFilter}
>
    Filter
</Button>
          <Button
variant="solid"
colorScheme="teal"
ml="4"
leftIcon={<Icon as={FiPlus} />}
onClick={handleAddDirectIncome}
disabled={!formData.date || !formData.name || !formData.description || !formData.price}
>
Add Income
</Button>
</Flex>
<Table variant="striped" colorScheme="teal" maxWidth="800px" mt={8}>
<Thead>
<Tr>
<Th>Date</Th>
<Th>Name</Th>
<Th>Description</Th>
<Th>Price</Th>
<Th>Payment Method</Th>
<Th>Reference Number</Th>
<Th>Actions</Th>
</Tr>
</Thead>
<Tbody>
{directIncomeData.slice().reverse().map((income,index) => (
<Tr key={income.id}>
<Td>{new Date(income.date).toLocaleDateString()}</Td> 
<Td>{income.name}</Td>
<Td>{income.description}</Td>
<Td>{income.price}</Td>
<Td>{income.paymentMethod}</Td>
<Td>{income.referenceNumber}</Td>
<Td>
<Flex>
<Button
   leftIcon={<Icon as={FiEdit} />}
   colorScheme="blue"
   variant="solid" mr={2}

onClick={() => handleEdit(income, 'direct')}
>
Edit
</Button>
<Button
   leftIcon={<Icon as={FiTrash2} />}
   colorScheme="red"
   variant="solid"
onClick={() => handleDelete(income._id, 'direct')}
>
Delete
</Button>
</Flex>
</Td>
</Tr>
))}
</Tbody>
</Table>
</Flex>


<Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleFilterSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() => setIsFilterModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
<Modal isOpen={showDirectFormModal} onClose={() => { 
    setShowDirectFormModal(false)
    // Call your function here
    clearFormData();
}}>
<ModalOverlay />
<ModalContent>
<ModalHeader>{selectedIncomeId ? "Edit Direct Income" : "Add Direct Income"}</ModalHeader>
<ModalCloseButton />
<ModalBody>
<FormControl mt={4}>
<FormLabel>Date</FormLabel>
<Input type="date" name="date" value={formData.date} onChange={handleChange} />
</FormControl>
<FormControl mt={4}>
<FormLabel>Name</FormLabel>
<Input type="text" name="name" value={formData.name} onChange={handleChange} />
</FormControl>
<FormControl mt={4}>
<FormLabel>Description</FormLabel>
<Input type="text" name="description" value={formData.description} onChange={handleChange} />
</FormControl>
<FormControl mt={4}>
<FormLabel>Price</FormLabel>
<Input type="number" name="price" value={formData.price} onChange={handleChange} />
</FormControl>
<FormControl mt={4}>
            <FormLabel>Payment Method</FormLabel>
            <RadioGroup defaultValue={formData.paymentMethod} onChange={val => setFormData({ ...formData, paymentMethod: val })}>
              <Flex direction="row">
                <Radio mr={4} value="Bank">Bank</Radio>
                <Radio value="Cash">Cash</Radio>
              </Flex>
            </RadioGroup>
          </FormControl>
          {formData.paymentMethod === 'Bank' && (
            <FormControl mt={4}>
              <FormLabel>Reference Number</FormLabel>
              <Input type="text" name="referenceNumber" value={formData.referenceNumber} onChange={handleChange} />
            </FormControl>
          )}
</ModalBody>
<ModalFooter>
<Button colorScheme="teal" onClick={handleSubmitDirectIncome}>Submit</Button>
</ModalFooter>
</ModalContent>
</Modal>
</>
)}
{showIndirectIncome && (
<>
<Center>
      <Heading as="h2" size="lg" mb="4">
      Indirect Income Information
      </Heading>
    </Center>
<Flex flexDirection="column" alignItems="center">
<Flex justifyContent="space-between" width="100%" mb={4}>
<Button
variant="solid"
colorScheme="teal"
leftIcon={<Icon as={FiArrowLeft} />}
onClick={() => setShowIndirectIncome(false)}
alignSelf="flex-start"
>
Back
</Button>

<Button
                    variant="outline"
                    colorScheme="teal"
                    ml="4"
              leftIcon={<Icon as={FiEdit} />}
              onClick={handleExportIndirectIncomeToExcel}
              >
              Export to Excel
              </Button>
              <Button
                 variant="outline"
                 colorScheme="teal"
                 ml="4"
            leftIcon={<Icon as={FiEdit} />}
            onClick={handleExportIndirectIncomeToJSON}
          >
            Export to JSON
          </Button>
          <Button
      ml={4}

  variant="outline"
  colorScheme="teal"
  leftIcon={<Icon as={FiPaperclip} />}
  onClick={exportIncomeToPDF}
>
  Export to PDF
</Button>
          <Button
    variant="outline"
    colorScheme="teal"
    ml="4"
    leftIcon={<Icon as={FiFilter} />}
    onClick={handleFilter}
>
    Filter
</Button>
          <Button
variant="solid"
colorScheme="teal"
ml="4"
leftIcon={<Icon as={FiPlus} />}
onClick={handleAddIndirectIncome}
disabled={!formData.date || !formData.name || !formData.description || !formData.price}
>
Add Income
</Button>
</Flex>
<Table variant="striped" colorScheme="teal" maxWidth="800px" mt={8}>
<Thead>
<Tr>
<Th>Date</Th>
<Th>Name</Th>
<Th>Description</Th>
<Th>Price</Th>
<Th>Payment Method</Th>
<Th>Reference Number</Th>
<Th>Actions</Th>
</Tr>
</Thead>
<Tbody>
{indirectIncomeData.slice().reverse().map((income,index) => (
<Tr key={income.id}>
<Td>{new Date(income.date).toLocaleDateString()}</Td> 
<Td>{income.name}</Td>
<Td>{income.description}</Td>
<Td>{income.price}</Td>
<Td>{income.paymentMethod}</Td>
<Td>{income.referenceNumber}</Td>
<Td>
<Flex>
<Button
 leftIcon={<Icon as={FiEdit} />}
 colorScheme="blue"
 variant="solid" mr={2}
onClick={() => handleEdit(income, 'indirect')}
>
Edit
</Button>
<Button
   leftIcon={<Icon as={FiTrash2} />}
   colorScheme="red"
   variant="solid"
onClick={() => handleDelete(income._id, 'indirect')}
>
Delete
</Button>
</Flex>
</Td>
</Tr>
))}
</Tbody>
</Table>
</Flex>
<Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleFilterSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() => setIsFilterModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
<Modal isOpen={showIndirectFormModal} onClose={() => { 
    setShowIndirectFormModal(false)
    // Call your function here
    clearFormData();
}}>
<ModalOverlay />
<ModalContent>
<ModalHeader>{selectedIncomeId ? "Edit Indirect Income" : "Add Indirect Income"}</ModalHeader>
<ModalCloseButton />
<ModalBody>
<FormControl mt={4}>
<FormLabel>Date</FormLabel>
<Input type="date" name="date" value={formData.date} onChange={handleChange} />
</FormControl>
<FormControl mt={4}>
<FormLabel>Name</FormLabel>
<Input type="text" name="name" value={formData.name} onChange={handleChange} />
</FormControl>
<FormControl mt={4}>
<FormLabel>Description</FormLabel>
<Input type="text" name="description" value={formData.description} onChange={handleChange} />
</FormControl>
<FormControl mt={4}>
<FormLabel>Price</FormLabel>
<Input type="number" name="price" value={formData.price} onChange={handleChange} />
</FormControl>
<FormControl mt={4}>
            <FormLabel>Payment Method</FormLabel>
            <RadioGroup defaultValue={formData.paymentMethod} onChange={val => setFormData({ ...formData, paymentMethod: val })}>
              <Flex direction="row">
                <Radio mr={4} value="Bank">Bank</Radio>
                <Radio value="Cash">Cash</Radio>
              </Flex>
            </RadioGroup>
          </FormControl>
          {formData.paymentMethod === 'Bank' && (
            <FormControl mt={4}>
              <FormLabel>Reference Number</FormLabel>
              <Input type="text" name="referenceNumber" value={formData.referenceNumber} onChange={handleChange} />
            </FormControl>
          )}
</ModalBody>
<ModalFooter>
<Button colorScheme="teal" onClick={handleSubmitIndirectIncome}>Submit</Button>
</ModalFooter>
</ModalContent>
</Modal>
</>
)}
      </div>
</>
);
};

export default Income;