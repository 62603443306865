import React, { useState, useEffect } from 'react';
import { Box, Button, Input, Text, Stack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { baseURL } from '../baseURL.js';

const LoginScreen = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(60); // Timer value starts at 60 seconds
  const [loadingLogin, setLoadingLogin] = useState(false); // Loading state for login button
  const [loadingSendOTP, setLoadingSendOTP] = useState(false); // Loading state for send OTP button
  const toast = useToast(); // Initialize useToast

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const handleLogin = async () => {
    setLoadingLogin(true); // Set loading state to true
    // Check if both username and email are not empty



    // If OTP is sent, verify it before logging in
    if (otpSent) {
      try {
        const otpResponse = await axios.post(`${baseURL}/api/verifyusersOTP`, {
          otp,
          email,
        });

        if (otpResponse.data.success) {
          toast({
            title: `Welcome ${username}`,
            status: 'success',
            duration: 10000,
            isClosable: true,
          });
          // If OTP verification is successful, save token and email to localStorage
          localStorage.setItem('email', otpResponse.data.email);
          // Call the onLogin callback
          onLogin();
        } else {
          // If OTP verification fails, display an error message
          if (
            otpResponse.status === 400 &&
            otpResponse.data.message === 'OTP expired. Please request a new one.'
          ) {
            // Handle expired OTP error
            toast({
              title: 'OTP expired',
              description: 'Please request a new one.',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
            // Reload the page to reset the login state
            window.location.reload();
          } else {
            // Display general OTP verification error
            console.error('Error verifying OTP:', otpResponse.data.message);
            toast({
              title: `${otpResponse.data.message}`,
              description: `Failed to verify OTP`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        }
      } catch (error) {
        console.error('Error verifying OTP:', error);
        toast({
          title: 'Failed to verify OTP.',
          description: 'Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoadingLogin(false); // Reset loading state
      }
    } else {
      // If OTP is not sent, proceed with login without OTP verification
      try {
        const response = await axios.post(`${baseURL}/api/userslogin`, {
          username,
          email,
        });

        if (response.status === 200) {
          // If login is successful, save token and email to localStorage
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('email', email);
          // Call the onLogin callback
          onLogin();
        } else {
          toast({
            title: 'Login failed',
            description: `${response.data.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error logging in:', error);
        toast({
          title: 'Failed to log in',
          description: 'Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoadingLogin(false); // Reset loading state
      }
    }
  };

  const handleSendOTP = async () => {
  

    // Regular expression for basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Check if username or email is empty
    if (username.trim() === '' || email.trim() === '') {
      toast({
        title: 'Username and email are required.',
        description: 'Please enter valid username and email.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setLoadingLogin(false); // Reset loading state
      return;
    }
    
    // Check if email format is valid
    if (!emailRegex.test(email)) {
      toast({
        title: 'Invalid email format',
        description: 'Please enter valid email',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setLoadingLogin(false); // Reset loading state
      return;
    }
    
    setLoadingSendOTP(true); // Set loading state to true
    try {
      const response = await axios.post(`${baseURL}/api/userslogin`, {
        username,
        email,
      });

      if (response.status === 200) {
        // If OTP is sent successfully, update state
        toast({
          title: `OTP sent to ${email}`,
          description: 'Please check and complate the verification process.',
          status: 'success',
          duration: 10000,
          isClosable: true,
        });
        setOtpSent(true);
        setTimer(120); // Reset timer to 120 seconds when OTP is sent
      } else {
        toast({
          title: 'OTP sending failed',
          description: `${response.data.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === 'OTP has already been sent. Please wait before requesting another OTP.'
      ) {
        // Display toast message for already sent OTP error
        toast({
          title: 'OTP has already been sent',
          description:
            'Please wait before requesting another OTP and try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Display general error message
        toast({
          title: 'Invaid username or email',
          description: 'To get access: Call +91 888 470 0189 or Email info@fpoaccountant.com',
          status: "info",
          duration: 10000,
          isClosable: true,
        });
      }
    } finally {
      setLoadingSendOTP(false); // Reset loading state
    }
  };

  return (
    <Box
      w="300px"
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      bg="white"
    >
      <Text fontSize="2xl" fontWeight="bold" mb={4} textAlign="center">
        Login
      </Text>
      <Stack spacing={4}>
        <Input
          placeholder="Username"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {otpSent && (
          <Input
            type="text"
            placeholder="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        )}
      </Stack>
      {!otpSent ? (
        <Button
          colorScheme="teal"
          mt={4}
          w="full"
          onClick={handleSendOTP}
          isLoading={loadingSendOTP}
          _hover={{ bg: 'teal.600' }}
        >
          Send OTP
        </Button>
      ) : (
        <Button
          colorScheme="teal"
          mt={4}
          w="full"
          onClick={handleLogin}
          isLoading={loadingLogin}
          _hover={{ bg: 'teal.600' }}
        >
          Login
        </Button>
      )}
      {otpSent && <Text textAlign="center">Time Remaining: {timer} seconds</Text>}
    </Box>
  );
};

export default LoginScreen;
