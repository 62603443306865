import React, { useState, useEffect } from 'react';
import {
  Flex,
  Icon,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Heading,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Text,
  useToast,
  Spinner,
  Card,
  CardBody,
} from '@chakra-ui/react';
import {
  FiArrowLeft,
  FiPlus,
  FiEdit,
  FiTrash2,
  FiDownload,
  FiFilter,
  FiPaperclip
} from 'react-icons/fi';
import { MdOutlineBusinessCenter } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { baseURL } from '../baseURL.js';

const Sales = () => {
  const [showB2B, setShowB2B] = useState(false);
  const [showB2C, setShowB2C] = useState(false);
  const [salesDataB2B, setSalesDataB2B] = useState([]);
  const [salesDataB2C, setSalesDataB2C] = useState([]);
  const [formData, setFormData] = useState({
    customer: '',
    invoiceNumber: '',
    date: new Date().toISOString().slice(0, 10),
    products: [{ productName: '', quantity: '', unitPrice: '', gstperproduct: '' }], // Make sure gstperproduct is included
    total: '',
    gstnumber: '',
  });  
  const [email,setEmail] = useState();
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    gstNumber: '',
    address: '',
    taluk: '',
    district: '',
    pincode: '',
    state: '',
    bankDetails: { // Ensure proper nested structure for bankDetails
      bankName: '',
      accountNumber: '',
      ifscCode: ''
    }
  });
  const [showFormModal, setShowFormModal] = useState(false);
  const [editId, setEditId] = useState(null); // Define editId state variable
  const [currentType, setCurrentType] = useState('');
  const [purchasesProducts, setPurchasesProducts] = useState([]);
  const [isQuantityValid, setIsQuantityValid] = useState(true);
  const [quantityError, setQuantityError] = useState('');
  const gstOptions = [null, "0", "5", "12", "18", "28"];
  const [selectedOption, setSelectedOption] = useState(null);
  
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  
  const [loading, setLoading] = useState(true); // Add loading state


  const [loadedGst, setLoadedGst] = useState(false);

  



  useEffect(() => {

        // Calculate one month ago from the current date
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        
        // Set start date to one month ago and end date to current date
        setStartDate(oneMonthAgo.toISOString().slice(0, 10));
        setEndDate(new Date().toISOString().slice(0, 10));
      
    fetchPurchasesProducts();
    fetchSales();
    fetchUserProfile();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
    
  const handleFilter = () => {
    setIsFilterModalOpen(true);
  };
  

  const handleFilterSubmit = () => {
    // Log start and end dates when filter is submitted
 

    fetchFilterSales()
    setIsFilterModalOpen(false);
  };

  const clearFormData = () => {
    setFormData({
        customer: '',
        invoiceNumber: '',
        date: '',
        products: [{ productName: '', quantity: '', unitPrice: '', gstperproduct: '' }],
        total: '',
        gstnumber: ''
    });
};



  const fetchPurchasesProducts = async () => {
    try {
        // Retrieve email from localStorage
        const storedEmail = localStorage.getItem('email');

        const response = await axios.get(`${baseURL}/api/purchases/aggregated-products`, {
            params: {
                email: storedEmail, // Sending email as a query parameter
            }
        });
        setPurchasesProducts(response.data);

        setLoading(false);
    } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message === "User profile not found") {

          if (!toast.isActive("profileNotFound")) {
            toast({
              id: "profileNotFound",
              title: "Please fill details on profile screen.",
              description: "Your profile details were incomplete",
              status: "info",
              duration: 10000,
              isClosable: true,
              position: 'bottom-left'
            });
          }
        }
    }
};



const fetchFilterSales = async () => {
  try {
      // Retrieve email from localStorage
      const storedEmail = localStorage.getItem('email');

      // Fetch sales data from the server
      const response = await axios.get(`${baseURL}/api/sales`, {
          params: {
              email: storedEmail, // Sending email as a query parameter
              startDate: startDate,
              endDate: endDate,
          }
      });



// Separate B2B and B2C sales data
const allSales = response.data || [];
let b2bSales = [];
let b2cSales = [];

allSales.forEach(sale => {
    if (sale.gstnumber !== "") {
        b2bSales.push(sale);
    } else {
        b2cSales.push(sale);
    }
});

// Filter B2B sales based on filteSelectedOption
const filteSelectedOption = selectedOption === "null" ? 0 : parseInt(selectedOption);


let filteredB2BSales = b2bSales;
if (!isNaN(filteSelectedOption)) {
    filteredB2BSales = b2bSales.map(sale => ({
        ...sale,
        products: sale.products.filter(product => product.gstperproduct === filteSelectedOption)
    })).filter(sale => sale.products.length > 0); // Remove sales with no matching products
    setLoadedGst(false)
  }
else{
  setLoadedGst(true)
}

let filteredB2CSales = b2cSales;
if (!isNaN(filteSelectedOption)) {
    filteredB2CSales = b2cSales.map(sale => ({
        ...sale,
        products: sale.products.filter(product => product.gstperproduct === filteSelectedOption)
    })).filter(sale => sale.products.length > 0); // Remove sales with no matching products
    setLoadedGst(false)
  }else{
  setLoadedGst(true)
}

if (isNaN(filteSelectedOption)) {
  setLoadedGst(true);
}

// Update state with separated sales data
setSalesDataB2B(filteredB2BSales);
setSalesDataB2C(filteredB2CSales);


setLoading(false);


  } catch (error) {
      console.error("Error fetching sales:", error);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {
        setLoading(false);
        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
  }
};




const fetchSales = async () => {
  try {
      // Retrieve email from localStorage
      const storedEmail = localStorage.getItem('email');

      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      // Fetch sales data from the server
      const response = await axios.get(`${baseURL}/api/sales`, {
          params: {
              email: storedEmail, // Sending email as a query parameter
              startDate: oneMonthAgo.toISOString().slice(0, 10), // Pass formatted start date as query parameter
              endDate: new Date().toISOString().slice(0, 10) // Pass formatted end date as query parameter
          }
      });

      // Separate B2B and B2C sales data
      const allSales = response.data || [];
      const b2bSales = [];
      const b2cSales = [];

      allSales.forEach(sale => {
          if (sale.gstnumber !==  "") {
              b2bSales.push(sale);
          } else {
              b2cSales.push(sale);
          }
      });

      // Update state with separated sales data
      setSalesDataB2B(b2bSales);
      setSalesDataB2C(b2cSales);
  

      setLoadedGst(true)
      setLoading(false);
  } catch (error) {
      console.error("Error fetching sales:", error);
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
  }
};

const handleDateChange = (e) => {
  const { value } = e.target;
  setFormData({ ...formData, date: value });
};


  const handleB2BClick = () => {
    setShowB2B(true);
    setShowB2C(false);
    setCurrentType('B2B');
  };

  const handleB2CClick = () => {
    setShowB2B(false);
    setShowB2C(true);
    setCurrentType('B2C');
  };

  const toast = useToast();


  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newProducts = [...formData.products];
    const productToUpdate = { ...newProducts[index] };
  
    switch (name) {
      case 'productName':
        productToUpdate.productName = value;
        break;
      case 'quantity':
        productToUpdate.quantity = value;
        break;
      case 'unitPrice':
        productToUpdate.unitPrice = value;
        break;
      case 'gstperproduct':
        productToUpdate.gstperproduct = value === "Null" ? null : parseFloat(value);
        break;
      default:
        break;
    }
  
    // Find the aggregated product data for the selected product
    const selectedProduct = purchasesProducts.find(product => product.productName === productToUpdate.productName);
  
    // Check if the entered quantity exceeds the total quantity available
    let updatedQuantity = parseInt(productToUpdate.quantity);
    if (selectedProduct && updatedQuantity > selectedProduct.totalQuantity) {
      updatedQuantity = selectedProduct.totalQuantity;
      toast({
        title: `Quantity should not exceed ${selectedProduct.totalQuantity} for ${selectedProduct.productName}`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
        position: "bottom-left"
      });
    }
  


    // Update the product quantity in the form data
    productToUpdate.quantity = updatedQuantity.toString();

    // Calculate total without taxes
    const totalWithoutTaxes = parseFloat(productToUpdate.quantity) * parseFloat(productToUpdate.unitPrice);
   
    // Calculate GST amount
    const gstPercentage = productToUpdate.gstperproduct || 0;

    const gstAmount = (totalWithoutTaxes * (gstPercentage / 100)).toFixed(2);


    // Calculate total with taxes
    const totalWithTaxes = (totalWithoutTaxes + parseFloat(gstAmount)).toFixed(2);

  
  
    // Update the product total in the form data
    productToUpdate.total = totalWithTaxes;
  
    // Calculate taxable amount
    const taxableAmount = totalWithoutTaxes.toFixed(2);
  
    // Update the form data with the new products array
    newProducts[index] = {
      ...productToUpdate,
      taxableAmount: taxableAmount // Update taxable amount
    };
  
    // Calculate total for all products including taxes
    const overallTotal = newProducts.reduce((acc, curr) => acc + parseFloat(curr.total), 0).toFixed(2);
  
    // Update the form data with the new products array and total
    setFormData(prevData => ({
      ...prevData,
      products: newProducts,
      total: overallTotal
    }));
  };
  
  
  const handleAddProduct = () => {
    setFormData(prevData => ({
      ...prevData,
      products: [...prevData.products, { productName: '', quantity: '', unitPrice: '', gstperproduct: '' }]
    }));
  };
  
  const handleRemoveProduct = (index) => {
    const newProducts = [...formData.products];
    newProducts.splice(index, 1);
  
    // Recalculate the total based on the updated products array
    const total = newProducts.reduce((acc, curr) => acc + parseFloat(curr.total), 0);
    const calculatedTotal = typeof total === 'number' && total >= 0 ? total.toFixed(2) : 0;
  
    setFormData((prevData) => ({
      ...prevData,
      products: newProducts,
      total: calculatedTotal,
    }));
  };
  

  const fetchUserProfile = async () => {
    try {
      const storedEmail = localStorage.getItem('email');
      setEmail(storedEmail);
      
      if (storedEmail) {
        const response = await axios.get(`${baseURL}/api/userprofiles?email=${storedEmail}`);
        setUserDetails(response.data.userDetails);
        
      } else {
        // Handle case where email is not stored
        console.error('Email not found in localStorage');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      if (error.response && error.response.data && error.response.data.message === "User profile not found") {
     
      
        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Your profile details were incomplete",
            description: "Please fill in your details.",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      } else {
        toast({
          title: "Error",
          description: "An error occurred while fetching your profile. Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };


  const generateInvoicePDF = async (sale) => {
    // Retrieve email from local storage
    const storedEmail = localStorage.getItem('email');
  
    if (storedEmail) {
      try {
        // Fetch user details using the stored email
        const response = await axios.get(`${baseURL}/api/userprofiles?email=${storedEmail}`);
        const userDetails = response.data.userDetails;
  
        
  
        // Proceed with PDF generation using the retrieved userDetails
        const doc = new jsPDF();
  
        // Add INVOICE text on the right top corner
        doc.setFontSize(24);
        const invoiceText = `INVOICE`;
        const invoiceTextWidth = doc.getStringUnitWidth(invoiceText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const rightMargin = 10;
        const topMargin = 20;
        const rightCornerX = doc.internal.pageSize.width - rightMargin;
        doc.text(rightCornerX - invoiceTextWidth, topMargin, invoiceText);
  
        // Set font size and style back to normal
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
  
        // Get date and format it as DD-MM-YYYY
        const saleDate = new Date(sale.date);
        const formattedDate = `${saleDate.getDate().toString().padStart(2, '0')}-${(saleDate.getMonth() + 1).toString().padStart(2, '0')}-${saleDate.getFullYear()}`;
  
        // Combine Customer and Company Details into one table
        const customerAndCompanyInfo = [
          [
            `Customer: ${sale.customer}${sale.customerNumber ? ' ' + sale.customerNumber : ''}`,
            `Company Name: ${userDetails.name}`
          ],
          [
            `GST Number: ${sale.gstnumber}`,
            `GST Number: ${userDetails.gstNumber}`
          ],
          [
            `Invoice Number: ${sale.invoiceNumber}`,
            `Bank Name: ${userDetails.bankDetails.bankName}`
          ],
          [
            `Date: ${formattedDate}`,
            `Account Number: ${userDetails.bankDetails.accountNumber}`
          ]
        ];
  
        doc.autoTable({
          head: [['Customer Details', 'Company Details']],
          body: customerAndCompanyInfo,
          startY: 40,
          theme: 'grid',
          styles: {
            fontSize: 12,
            cellPadding: 2,
            lineColor: [0, 0, 0], // Black color for table borders
            lineWidth: 0.1
          },
          headStyles: {
            fillColor: [192, 192, 192], // Gray color for header background
            textColor: [0, 0, 0], // Black color for header text
          },
          tableWidth: 'auto', // Automatically adjust table width
          margin: { left: 20 }
        });
  
        // Calculate total price for each product by multiplying quantity with price
        const productsWithTotalPrice = sale.products.map(product => {
          const totalPrice = product.quantity * product.unitPrice;
          const taxableAmount = totalPrice - product.gstAmount;
          return { ...product, totalPrice, taxableAmount };
        });
  
        // Add products table header
        const headers = [['Product', 'Quantity', 'Unit Price', 'GST per product', 'Taxable Amount', 'GST Amount', 'Total']];
        const data = productsWithTotalPrice.map(product => [
          product.productName,
          product.quantity,
          product.unitPrice.toFixed(2),
          product.gstperproduct.toFixed(2),
          product.taxableAmount.toFixed(2),
          product.gstAmount.toFixed(2),
          product.totalPrice.toFixed(2)
        ]);
  
        doc.autoTable({
          startY: doc.autoTable.previous.finalY + 10,
          head: headers,
          body: data,
          theme: 'grid',
          headStyles: {
            fillColor: [192, 192, 192], // Gray color for header background
            textColor: [0, 0, 0], // Black color for header text
          },
          styles: {
            lineColor: [0, 0, 0], // Black color for table borders
            lineWidth: 0.1,
            fontSize: 10 // Adjust font size to fit content better
          },
          columnStyles: {
            0: { cellWidth: 30 }, // Product
            1: { cellWidth: 20 }, // Quantity
            2: { cellWidth: 25 }, // Unit Price
            3: { cellWidth: 25 }, // GST per product
            4: { cellWidth: 30 }, // Taxable Amount
            5: { cellWidth: 25 }, // GST Amount
            6: { cellWidth: 25 }, // Total
          },
          margin: { top: 10 },
        });
  
        // Calculate Total Taxable Amount and Total GST Amount
        const totalTaxableAmount = productsWithTotalPrice.reduce((acc, curr) => acc + curr.taxableAmount, 0);
        const totalGSTAmount = sale.total - totalTaxableAmount;
  
        // Display total information after products table
        const totalInfo = [
          ['Total Taxable Amount', totalTaxableAmount.toFixed(2)],
          ['Total GST Amount', totalGSTAmount.toFixed(2)],
          ['Total', sale.total.toFixed(2)]
        ];
  
        doc.autoTable({
          startY: doc.autoTable.previous.finalY + 10,
          body: totalInfo,
          theme: 'grid',
          styles: {
            fontSize: 12,
            cellPadding: 2,
            lineColor: [0, 0, 0], // Black color for table borders
            lineWidth: 0.1
          },
          headStyles: {
            fillColor: [192, 192, 192], // Gray color for header background
            textColor: [0, 0, 0], // Black color for header text
          },
          tableWidth: 'wrap', // Automatically adjust table width
          margin: { left: 20 }
        });
  
        // Add "Thank you for your business!" centered at the bottom
        const footerText = 'Thank you for your business!';
        const footerTextWidth = doc.getStringUnitWidth(footerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const centerX = doc.internal.pageSize.width / 2;
        const bottomMargin = 20;
        const centerY = doc.internal.pageSize.height - bottomMargin;
        doc.text(centerX - footerTextWidth / 2, centerY, footerText, null, null, 'center');
  
        doc.save(`Invoice_${sale.invoiceNumber}.pdf`);
  
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    } else {
      // Handle case where email is not stored
      console.error('Email not found in localStorage');
    }
  };
  
  
  // Function to format date as DD-MM-YYYY
  function formatDate(date) {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }
  
  

  
  

  const handleDownloadInvoice = (sale) => {
    generateInvoicePDF(sale);
    toast({
      title: 'Invoice downloaded successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };


  const handleSubmit = async () => {


    formData.taxes = formData.taxes === "" ? 0 : formData.taxes;


    if (
      (!formData.customer || !formData.invoiceNumber || !formData.products.length || formData.total <= 0) || 
      (showB2B === true && showB2C === false && !formData.gstnumber)
  ) {
      toast({
          title: `Please fill in all details`,
          description: "Required Fields Missing",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: 'bottom-left'
      });
      return; // Return without submitting if validation fails
  }
  


    try {
      // Retrieve email from localStorage
      const email = localStorage.getItem('email');
  
      if (editId) {
        // If editId is set, it means we're updating an existing sale
        await axios.put(`${baseURL}/api/sales/${editId}`, { ...formData, email });
        // Handle success
        // toast({ title: 'Sale updated successfully', status: 'success' });
        toast({
          title: "Sale updated successfully",
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left'
      });
   

      } else {
        // Otherwise, we're creating a new sale
        await axios.post(`${baseURL}/api/sales`, { ...formData, email });
        // Handle success
    
        toast({
          title: 'Sale added successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left'
      });
      }
      clearFormData()
      // After successful submission, refetch sales data
      fetchSales();
      setShowFormModal(false); // Close the modal
    } catch (error) {
      // Handle error
      console.error('Error:', error);
      toast({ title: 'Error submitting sale', status: 'error' });

      if (error.response && error.response.data && error.response.data.message === "User profile not found") {

        if (!toast.isActive("profileNotFound")) {
          toast({
            id: "profileNotFound",
            title: "Please fill details on profile screen.",
            description: "Your profile details were incomplete",
            status: "info",
            duration: 10000,
            isClosable: true,
            position: 'bottom-left'
          });
        }
      }
      
    }
  };
  

  const handleEdit = (id) => {
    const salesData = currentType === 'B2B' ? salesDataB2B : salesDataB2C;
    const saleToEdit = salesData.find(sale => sale._id === id);
    if (saleToEdit) {
      setFormData({ ...saleToEdit }); // Populate form with sale data
      setEditId(id); // Set editId to the ID of the sale being edited
      setShowFormModal(true); // Open the modal
    }
  };
  

  const handleDelete = async (_id) => {
    try {
        // Retrieve email from localStorage
        const storedEmail = localStorage.getItem('email');

        // Include the email as a query parameter in the request
        await axios.delete(`${baseURL}/api/sales/${_id}`, {
            params: {
                email: storedEmail
            }
        });

      

        toast({
          title: 'Sale deleted successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left'
      });

        // Refetch sales data after deletion
        fetchSales();
    } catch (error) {
        // Handle error
        console.error('Error:', error);

        toast({
          title: 'Error deleting sale',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'bottom-left'
      });
    }
};

const exportToPDF = () => {

  console.log(salesDataB2B)
  let totalSum = 0; // Variable to store the sum of either total or price

  const isB2B = showB2B;
  const salesData = isB2B ? salesDataB2B : salesDataB2C;

  // Define headers conditionally based on B2B or B2C
  const headers = [
    'Date', 'Customer', 'Invoice Number',
    ...(isB2B ? ['GST Number'] : []), // Add GST Number only for B2B
    'Products', 'Quantity', 'Unit Price', 'Taxable Amount', 'GST (%)', 'GST Amount', 'Total'
  ];

  const data = salesData.map((sale) => {
    const rowData = [
      new Date(sale.date).toLocaleDateString(),
      sale.customer,
      sale.invoiceNumber,
      ...(isB2B ? [sale.gstnumber] : []), // Add GST Number only for B2B
      sale.products.map((product) => product.productName).join('\n'),
      sale.products.map((product) => product.quantity).join('\n'),
      sale.products.map((product) => product.unitPrice).join('\n'),
      sale.products.map((product) => product.taxableAmount).join('\n'),
      sale.products.map((product) => product.gstperproduct).join('\n'),
      sale.products.map((product) => product.gstAmount).join('\n'),
      sale.total
    ];

    totalSum += sale.total; // Add the total to the totalSum
    return rowData;
  });

  const pdf = new jsPDF();

  // Add Sales Information heading
  pdf.setFontSize(18);
  pdf.text(
    `Sales Information from ${startDate} till ${endDate}` + 
    (isB2B ? '' : ` with the ${selectedOption}% GST `),
    14, 20
  );

  // Generate autoTable
  pdf.autoTable({
    head: [headers],
    body: data,
    startY: 30, // Adjust the starting Y position as needed
    theme: 'grid',
    headStyles: {
      fillColor: [211, 211, 211], // Set heading background color to light gray (RGB: 211, 211, 211)
      textColor: [0, 0, 0] // Set heading text color to black
    },
    styles: {
      lineColor: [0, 0, 0], // Set line color to black
      textColor: [0, 0, 0], // Set text color to black
      fontStyle: 'normal', // Set font style to normal
      lineWidth: 0.1, // Set line width
      fontSize: 6 
    }
  });

  // Display the sum of either total or price at the end
  pdf.setFontSize(12);
  pdf.text(`Total Sum: ${totalSum.toFixed(2)}`, 14, pdf.lastAutoTable.finalY + 10);

  pdf.save('sales.pdf');
};


const handleExportB2BToJson = () => {
  // Prepare data for export and change date format
  const dataForExport = salesDataB2B.map(({ date,_id, __v, email, products, ...rest }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }); // DD-MM-YYYY

    const productDetails = products.map(({ productName, quantity, unitPrice, gstperproduct, taxableAmount, gstAmount }) => ({
      'Product Name': productName,
      'Quantity': quantity,
      'Price': unitPrice,
      'GST Per Product': gstperproduct,
      'Taxable Amount': taxableAmount,
      'GST Amount': gstAmount
    }));

    return {
      ...rest,
      'Date': formattedDate,
      ...productDetails.reduce((acc, product, index) => {
        return { ...acc, [`Product ${index + 1}`]: product };
      }, {})
    };
  });

  // Get the date from the first entry in the data
  const firstEntryDate = dataForExport.length > 0 ? dataForExport[0].Date : 'unknown_date';

  // Convert data to JSON
  const jsonData = JSON.stringify(dataForExport, null, 2);

  // Create a Blob object from the JSON data
  const blob = new Blob([jsonData], { type: 'application/json' });

  // Save the Blob object as a file with the correct date in the file name
  FileSaver.saveAs(blob, `B2B_Sales_${firstEntryDate}.json`);
};



const handleExportB2CToJson = () => {
  // Prepare data for export and change date format
  const dataForExport = salesDataB2C.map(({ date,_id, __v, email, products, ...rest }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }); // DD-MM-YYYY

    const productDetails = products.map(({ productName, quantity, unitPrice, gstperproduct, taxableAmount, gstAmount }) => ({
      'Product Name': productName,
      'Quantity': quantity,
      'Price': unitPrice,
      'GST Per Product': gstperproduct,
      'Taxable Amount': taxableAmount,
      'GST Amount': gstAmount
    }));

    return {
      ...rest,
      'Date': formattedDate,
      ...productDetails.reduce((acc, product, index) => {
        return { ...acc, [`Product ${index + 1}`]: product };
      }, {})
    };
  });

  // Get the date from the first entry in the data
  const firstEntryDate = dataForExport.length > 0 ? dataForExport[0].Date : 'unknown_date';

  // Convert data to JSON
  const jsonData = JSON.stringify(dataForExport, null, 2);

  // Create a Blob object from the JSON data
  const blob = new Blob([jsonData], { type: 'application/json' });

  // Save the Blob object as a file with the correct date in the file name
  FileSaver.saveAs(blob, `B2C_Sales_${firstEntryDate}.json`);
};

const handleExportB2BToExcel = () => {
  console.log(salesDataB2B);

  // Prepare data for export and change date format
  const dataForExport = salesDataB2B.map(({ _id, __v, email, date, products, ...rest }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }); // DD-MM-YYYY

    const productDetails = products.map(({ productName, quantity, unitPrice, taxableAmount, gstperproduct, gstAmount }) => ({
      'Product Name': productName,
      'Quantity': quantity,
      'Price': unitPrice,
      'Taxable Amount': taxableAmount,
      'GST per Product': gstperproduct,
      'GST Amount': gstAmount
    }));

    const allFields = Object.keys(rest).map(key => ({
      [key]: rest[key]
    }));

    return {
      ...Object.assign({}, ...allFields),
      'Date': formattedDate,
      ...productDetails.reduce((acc, product, index) => {
        return {
          ...acc,
          [`Product Name ${index + 1}`]: product['Product Name'],
          [`Quantity ${index + 1}`]: product['Quantity'],
          [`Price ${index + 1}`]: product['Price'],
          [`Taxable Amount ${index + 1}`]: product['Taxable Amount'],
          [`GST per Product ${index + 1}`]: product['GST per Product'],
          [`GST Amount ${index + 1}`]: product['GST Amount']
        };
      }, {})
    };
  });

  // Convert data to worksheet
  const ws = XLSX.utils.json_to_sheet(dataForExport);

  // Create workbook and add worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'B2B Sales');

  // Save workbook as Excel file
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

  // Get the date from the first entry in the data
  const firstEntryDate = dataForExport.length > 0 ? dataForExport[0].Date : 'unknown_date';

  // Save workbook as Excel file with the correct date in the file name
  FileSaver.saveAs(data, `B2B_Sales_${firstEntryDate}.xlsx`);
};


const handleExportB2CToExcel = () => {
  console.log(salesDataB2C);

  // Prepare data for export and change date format
  const dataForExport = salesDataB2C.map(({ _id, __v, email, date,gstnumber, products, ...rest }) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }); // DD-MM-YYYY

    const productDetails = products.map(({ productName, quantity, unitPrice, taxableAmount, gstperproduct, gstAmount }) => ({
      'Product Name': productName,
      'Quantity': quantity,
      'Price': unitPrice,
      'Taxable Amount': taxableAmount,
      'GST % per Product': gstperproduct,
      'GST Amount': gstAmount
    }));

    const allFields = Object.keys(rest).map(key => ({
      [key]: rest[key]
    }));

    return {
      ...Object.assign({}, ...allFields),
      'Date': formattedDate,
      ...productDetails.reduce((acc, product, index) => {
        return {
          ...acc,
          [`Product Name ${index + 1}`]: product['Product Name'],
          [`Quantity ${index + 1}`]: product['Quantity'],
          [`Price ${index + 1}`]: product['Price'],
          [`Taxable Amount ${index + 1}`]: product['Taxable Amount'],
          [`GST per Product ${index + 1}`]: product['GST per Product'],
          [`GST Amount ${index + 1}`]: product['GST Amount']
        };
      }, {})
    };
  });

  // Convert data to worksheet
  const ws = XLSX.utils.json_to_sheet(dataForExport);

  // Create workbook and add worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'B2C Sales');

  // Save workbook as Excel file
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

  // Get the date from the first entry in the data
  const firstEntryDate = dataForExport.length > 0 ? dataForExport[0].Date : 'unknown_date';

  // Save workbook as Excel file with the correct date in the file name
  FileSaver.saveAs(data, `B2C_Sales_${firstEntryDate}.xlsx`);
};


  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
    {loading && (
      <div style={{ backdropFilter: 'blur(5px)', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
        <Spinner thickness="4px" speed="0.5s" emptyColor="gray.200" color="teal" size="xl" />
      </div>
    )}
    <>
      {!showB2B && !showB2C && (
        <>
       
          <Center>
      <Heading as="h2" size="lg" mb="4">
      Sales category
      </Heading>
    </Center>
          <Flex justifyContent="center">
            <Box
              bg="gray.200"
              p={6}
              m={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)' }}
              onClick={handleB2BClick}
            >
              <Icon as={MdOutlineBusinessCenter} boxSize={24} />
              <Text fontSize="xl" fontWeight="bold" mt={2}>
                B2B Entry
              </Text>
            </Box>
            <Box
              bg="gray.200"
              p={6}
              m={4}
              borderRadius="lg"
              boxShadow="md"
              textAlign="center"
              cursor="pointer"
              transition="all 0.3s"
              _hover={{ transform: 'scale(1.05)' }}
              onClick={handleB2CClick}
            >
              <Icon as={CgProfile} boxSize={24} />
              <Text fontSize="xl" fontWeight="bold" mt={2}>
                B2C Entry
              </Text>
            </Box>
          </Flex>
        </>
      )}
      {(showB2B || showB2C) && (
        <>
         <Center>
         <Heading as="h2" size="lg" mb="4">
            {showB2B ? 'B2B Sales Information' : 'B2C Sales Information'}
          </Heading>
    </Center>
          <Flex flexDirection="column" alignItems="center">
            <Flex justifyContent="space-between" width="100%" mb={4}>
              <Button
                variant="solid"
                colorScheme="teal"
                leftIcon={<Icon as={FiArrowLeft} />}
                onClick={() => {
                  setShowB2B(false);
                  setShowB2C(false);
                }}
                alignSelf="flex-start"
              >
                Back
              </Button>
              {showB2B && (
                <>
                <Button
                 variant="outline"
                 colorScheme="teal"
                  leftIcon={<Icon as={FiDownload} />}
                  onClick={handleExportB2BToExcel}
                  mb={4}
                >
                  Export B2B to Excel
                </Button>
                <Button
           variant="outline"
           colorScheme="teal"
                leftIcon={<Icon as={FiDownload} />}
                onClick={handleExportB2BToJson}
                mb={4}
              >
                Export B2B to JSON
              </Button>
              <Button
  variant="outline"
  colorScheme="teal"
  leftIcon={<Icon as={FiPaperclip} />}
  onClick={exportToPDF}
>
  Export to PDF
</Button>

              </>
              )}
              {showB2C && (
                <>
                <Button
                   variant="outline"
                   colorScheme="teal"
                  leftIcon={<Icon as={FiDownload} />}
                  onClick={handleExportB2CToExcel}
                  mb={4}
                >
                  Export B2C to Excel
                </Button>
                <Button
             variant="outline"
             colorScheme="teal"
                leftIcon={<Icon as={FiDownload} />}
                onClick={handleExportB2CToJson}
                mb={4}
              >
                Export B2C to JSON
              </Button>
              <Button
  variant="outline"
  colorScheme="teal"
  leftIcon={<Icon as={FiPaperclip} />}
  onClick={exportToPDF}
>
  Export to PDF
</Button>
              </>
              )}

<Button
    variant="outline"
    colorScheme="teal"
    leftIcon={<Icon as={FiFilter} />}
    onClick={handleFilter}
>
    Filter
</Button>
              <Button
                variant="solid"
                colorScheme="teal"
                leftIcon={<Icon as={FiPlus} />}
                onClick={() => setShowFormModal(true)}
                mb={4}
              >
                New Sale
              </Button>
            </Flex>
            <Table variant="striped" colorScheme="teal" maxWidth="800px">
  <Thead>
    <Tr>
      <Th p={2}>Date</Th>
      <Th p={2}>Customer</Th>
      <Th p={2}>Invoice Number</Th>
      {!showB2C && <Th p={2}>GST Number</Th>}
      <Th p={2}>Product</Th>
      <Th p={2}>Quantity</Th>
      <Th p={2}>Rate/Unit</Th>
      {loadedGst && (
        <>
          <Th p={2}>Taxable Amount</Th>
          <Th p={2}>GST%</Th>
          <Th p={2}>GST Amount</Th>
          <Th p={2}>Amount</Th>
          <Th p={2}>Action</Th>
        </>
      )}
    </Tr>
  </Thead>
  <Tbody>
    {(showB2B ? salesDataB2B : salesDataB2C).slice().reverse().map((sale) => (
      <Tr key={sale._id}>
        <Td p={2}>{new Date(sale.date).toLocaleDateString()}</Td>
        <Td p={2}>{sale.customer}</Td>
        <Td p={2}>{sale.invoiceNumber}</Td>
        {!showB2C && <Td p={2}>{sale.gstnumber}</Td>}
        <Td p={2}>
          {sale.products.length > 0 ? (
            sale.products.map((product, idx) => (
              <div key={idx} style={{ padding: 2 }}>
                <div>{product.productName}</div>
              </div>
            ))
          ) : (
            <div>No Products Available</div>
          )}
        </Td>
        <Td p={2}>
          {sale.products.length > 0 ? (
            sale.products.map((product, idx) => (
              <div key={idx} style={{ padding: 2 }}>
                <div>{product.quantity}</div>
              </div>
            ))
          ) : (
            <div>No Quantity Available</div>
          )}
        </Td>
        <Td p={2}>
          {sale.products.length > 0 ? (
            sale.products.map((sale, idx) => (
              <div key={idx} style={{ padding: 2 }}>
                <div>{sale.unitPrice}</div>
              </div>
            ))
          ) : (
            <div>No Rate/Unit Available</div>
          )}
        </Td>
        <Td p={2}>
          {sale.products.length > 0 ? (
            sale.products.map((sale, idx) => (
              <div key={idx} style={{ padding: 2 }}>
                <div>{sale.taxableAmount}</div>
              </div>
            ))
          ) : (
            <div>No Taxable Amount Available</div>
          )}
        </Td>
        <Td p={2}>
          {sale.products.length > 0 ? (
            sale.products.map((product, idx) => (
              <div key={idx} style={{ padding: 2 }}>
                <div>{product.gstperproduct}%</div>
              </div>
            ))
          ) : (
            <div>No GST Available</div>
          )}
        </Td>
        <Td p={2}>
          {sale.products.length > 0 ? (
            sale.products.map((product, idx) => (
              <div key={idx} style={{ padding: 2 }}>
                <div>{product.gstAmount}</div>
              </div>
            ))
          ) : (
            <div>No Price Available</div>
          )}
        </Td>
        {loadedGst && (
          <>
            <Td p={2}>{sale.total}</Td>
            <Td p={2}>
              <Flex p={2}>
                <Button
                  leftIcon={<Icon as={FiDownload} />}
                  colorScheme="teal"
                  variant="solid"
                  mr={2}
                  onClick={() => handleDownloadInvoice(sale)}
                >
                  Invoice
                </Button>
                <Button
                  leftIcon={<Icon as={FiEdit} />}
                  colorScheme="blue"
                  variant="solid"
                  mr={2}
                  onClick={() => handleEdit(sale._id)}
                >
                  Edit
                </Button>
                <Button
                  leftIcon={<Icon as={FiTrash2} />}
                  colorScheme="red"
                  variant="solid"
                  onClick={() => handleDelete(sale._id)}
                >
                  Delete
                </Button>
              </Flex>
            </Td>
          </>
        )}
      </Tr>
    ))}
  </Tbody>
</Table>

          </Flex>


<Modal isOpen={isFilterModalOpen} onClose={() => setIsFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Dates</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Start Date</FormLabel>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>End Date</FormLabel>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </FormControl>
            <FormControl mt={4}>
            <Select
        placeholder="Select GST option"
        value={selectedOption}
        onChange={handleSelectChange}
      >
        {gstOptions.map((option, index) => (
          <option key={index} value={option}>{option === null ? "null" : `${option}%`}</option>
        ))}
      </Select>
      {selectedOption !== null && (
        <p>GST selected: {selectedOption === "null" ? "null" : `${selectedOption}%`}</p>
      )}
             </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleFilterSubmit}>Submit</Button>
            <Button variant="ghost" ml={2} onClick={() => setIsFilterModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      

      <Modal isOpen={showFormModal} onClose={() => { 
    setShowFormModal(false)
    // Call your function here
    clearFormData();
}}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{editId ? 'Edit Sales Information' : 'Add New Sales Information'}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {quantityError && (
                  <Text color="red.500" mb={3}>
                    {quantityError}
                  </Text>
                )}
                <FormControl mb={3}>
                  <FormLabel>Customer</FormLabel>
                  <Input
                    type="text"
                    name="customer"
                    value={formData.customer}
                    onChange={(e) => setFormData({ ...formData, customer: e.target.value })}
                  />
                </FormControl>
                <FormControl mb={3}>
                  <FormLabel>Invoice Number</FormLabel>
                  <Input
                    type="text"
                    name="invoiceNumber"
                    value={formData.invoiceNumber}
                    onChange={(e) => setFormData({ ...formData, invoiceNumber: e.target.value })}
                  />
                </FormControl>
                <FormControl mb={3}>
                  <FormLabel>Date</FormLabel>
                  <Input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  />
                </FormControl>
               
                {formData.products.map((product, index) => (
                  <div key={index}>
                     <Card mt="4">
                  <CardBody>
                    <FormControl mb={3}>
                      <FormLabel>Product</FormLabel>
                      <Select
  name="productName"
  value={product.productName}
  onChange={(e) => handleChange(e, index)}
>
  <option disabled value="">Select a product</option> 
  {purchasesProducts.map(product => (
    <option key={product._id} value={product.productName}>
      {product.productName}
    </option>
  ))}
</Select>
                    </FormControl>
                    <FormControl mb={3}>
                      <FormLabel>Quantity</FormLabel>
                      <Input
                        type="number"
                        name="quantity"
                        value={product.quantity}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </FormControl>
            <FormControl mb={3}>
  <FormLabel>Price</FormLabel>
  <Input
    type="number"
    name="unitPrice"
    value={product.unitPrice}
    onChange={(e) => handleChange(e, index)}
  />
</FormControl>

<FormControl mb={3}>
  <FormLabel>Product GST Taxes (%)</FormLabel>
  <Select
  name="gstperproduct"
  value={product.gstperproduct}
  onChange={(e) => handleChange(e, index)}
>
  <option value={null}>Null</option>
  {gstOptions.slice(1).map((option, index) => ( // Start from index 1 to skip null
    <option key={index} value={option}>{option}</option>
  ))}
</Select>
</FormControl>
</CardBody>
</Card>

                    {index > 0 && (
                      <Button variant="ghost" colorScheme="red" onClick={() => handleRemoveProduct(index)}>Remove</Button>
                    )}
                  </div>
                ))}
<Flex justify="center">
<Button mt={2} mb={2} mr={2} colorScheme="teal" onClick={handleAddProduct}>Add Another Product</Button>
</Flex>
                {currentType === 'B2B' && (
                  <FormControl mb={3}>
                    <FormLabel>GST Number</FormLabel>
                    <Input
                      name="gstnumber"
                      value={formData.gstnumber}
                      onChange={(e) => setFormData({ ...formData, gstnumber: e.target.value })}
                    />
                  </FormControl>
                )}


<FormControl mb={3}>
<Text textAlign="center" fontSize="2xl">{`Total: ${formData.total ? formData.total : 0} Rs`}</Text>
</FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="teal" mr={3} onClick={handleSubmit} disabled={!isQuantityValid}>
                  {editId ? 'Update' : 'Submit'}
                </Button>
                <Button variant="ghost" onClick={() => setShowFormModal(false)}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
    </div>
  );
};

export default Sales;
